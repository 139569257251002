import React from "react";
import { Icon } from "./Icon";

interface LoadingSpinnerProps {
  icon?: "spinner" | "cog";
  title?: string;
}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return <Icon symbol={props.icon || "spinner"} spin title={props.title} />;
}
