import React, { CSSProperties } from "react";
import { combineClassNames } from "../../utils/reactHelpers";

interface NumberInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: number | undefined;
  min?: number;
  max?: number;
  required?: boolean;
  integer?: boolean;
  onChange: (value: number | undefined) => any;
  onEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  debounce?: number;
  errorAnnotation?: string;
  inline?: boolean;
  width?: string;
}

interface NumberInputState {
  isFocused: boolean;
  inputVal: string;
}

export class NumberInput extends React.PureComponent<NumberInputProps, NumberInputState> {
  inputRef = React.createRef<HTMLInputElement>();

  private debouncedOnChangeTimeout: number | undefined;
  private debouncedValueToCommit: number | undefined;

  constructor(props: NumberInputProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = {
      isFocused: false,
      inputVal: (props.value || "").toString(),
    };
  }

  render() {
    const { value, min, max, required, integer, onEnter, inline, className, debounce, errorAnnotation, width, ...restAttrs } =
      this.props;
    let val = this.state.isFocused ? this.state.inputVal : value;
    if (val === undefined || val === null) val = "";
    const clsWrapper = combineClassNames("input-wrapper number", inline ? "inline" : undefined);
    const classNames = combineClassNames("input-number", className, errorAnnotation && "has-error");
    let wrapperStyle: CSSProperties | undefined;
    if (width) {
      wrapperStyle = {
        width,
      };
    }

    return (
      <div className={clsWrapper} style={wrapperStyle}>
        <input
          {...restAttrs}
          className={classNames}
          ref={this.inputRef}
          type="text"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          value={val}
        />
        {errorAnnotation && <div className="error-field-annotation">{errorAnnotation}</div>}
      </div>
    );
  }

  componentWillUnmount(): void {
    this.clearDebouncedOnChange();
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      inputVal: ev.target.value,
    });
    this.updateModel(ev.target.value);
  }

  private callOnChange(value: number | undefined) {
    this.clearDebouncedOnChange();

    if (this.props.debounce) {
      this.debouncedValueToCommit = value;
      this.debouncedOnChangeTimeout = window.setTimeout(() => {
        this.props.onChange(value);
        this.clearDebouncedOnChange();
      }, this.props.debounce);
    } else {
      this.props.onChange(value);
    }
  }

  private clearDebouncedOnChange() {
    if (this.debouncedOnChangeTimeout) {
      window.clearTimeout(this.debouncedOnChangeTimeout);
    }

    this.debouncedOnChangeTimeout = undefined;
    this.debouncedValueToCommit = undefined;
  }

  private commitWaitingDebouncedValue() {
    if (this.debouncedValueToCommit !== undefined && this.debouncedOnChangeTimeout) {
      this.props.onChange(this.debouncedValueToCommit);
      this.clearDebouncedOnChange();
    }
  }

  onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
    this.props.onKeyDown && this.props.onKeyDown(ev);

    if (this.props.onEnter && ev.key === "Enter") {
      this.props.onEnter(ev);
    }
  }

  onFocus(ev: React.FocusEvent<HTMLInputElement>) {
    this.inputRef.current!.select();
    this.setState({
      inputVal: ev.target.value,
      isFocused: true,
    });
    this.props.onFocus && this.props.onFocus(ev);
  }

  onBlur(ev: React.FocusEvent<HTMLInputElement>) {
    this.setState({
      isFocused: false,
    });
    this.props.onBlur && this.props.onBlur(ev);
  }

  updateModel(newValue: any | undefined) {
    if (typeof newValue !== "string" || newValue === "") {
      this.props.onChange(this.getDefaultValue());
      return;
    }

    newValue = newValue.replace(",", ".");
    const number = parseFloat(newValue);
    const modelValue = isNaN(number) ? this.getDefaultValue() : this.restrictNewModel(number);
    this.callOnChange(modelValue);
  }

  getDefaultValue() {
    return this.props.required ? this.props.min : undefined;
  }

  restrictNewModel(value: number): number {
    if (typeof this.props.min === "number" && value < this.props.min) {
      return this.props.min;
    }

    if (typeof this.props.max === "number" && value > this.props.max) {
      return this.props.max;
    }

    if (this.props.integer) {
      return Math.floor(value);
    }

    return value;
  }
}
