import React, { useContext } from "react";
import { SelectMonth } from "../controls/SelectMonth";
import { FormContext } from "./FormContext";

type SelectMonthProps = React.ComponentProps<typeof SelectMonth>;

interface SelectMonthFieldProps extends Omit<SelectMonthProps, "value" | "onChange"> {
  name: string;
}

export const SelectMonthField = React.memo(function SelectMonthField(props: SelectMonthFieldProps) {
  const form = useContext(FormContext);
  const { name, ...restAttrs } = props;
  const value = form.model[name];

  return (
    <SelectMonth
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
