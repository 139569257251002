import React from "react";
import { Route, HashRouter, Routes } from "react-router-dom";
import { ItcModal } from "./windows/ItcModal";
import { MeasurePointsProxysiteModal } from "./windows/MeasurePointsProxysiteModalWindow";
import { MainView } from "./MainView";
import { WebClientMedataReporter } from "core/ws/WebClientMedataReporter";
import { ClientExposedConfigRefresher } from "core/global/ClientExposedConfigRefresher";
import { ToastContextProvider } from "core/content/toast/ToastContextProvider";
import { AppNotificationToToastListener } from "core/notifications/AppNotificationToToastListener";
import { AutoLogoutDueToInactivityGuard } from "core/auth/AutoLogoutDueToInactivityGuard";
import { AppConditionContextProvider } from "domain/maintenance/appCondition/AppConditionContext";
import { PresentationModeResetterDueToInactivity } from "domain/admin/presentationMode/PresentationModeResetterDueToInactivity";

export function MainRouter() {
  return (
    <ToastContextProvider>
      <AppConditionContextProvider>
        <HashRouter>
          <AutoLogoutDueToInactivityGuard />
          <PresentationModeResetterDueToInactivity />
          <AppNotificationToToastListener />
          <WebClientMedataReporter />
          <ClientExposedConfigRefresher />
          <Routes>
            {/* <Route path="/itc/:id/webinterface" element={<ItcWebInterfaceModal />} /> */}
            <Route path="/itc/:id" element={<ItcModal />} />
            <Route path="/measurepointsproxy" element={<MeasurePointsProxysiteModal />} />
            <Route path="/test" element={<TestComponent />} />
            <Route path="*" element={<MainView />} />
          </Routes>
        </HashRouter>
      </AppConditionContextProvider>
    </ToastContextProvider>
  );
}

function TestComponent(props: any) {
  return <span>Test</span>;
}
