import { tx } from "core/intl";
import { toClassNames } from ".";
import { FeedbackMessage, Text } from "../content";
import { LoadingSpinner } from "./LoadingSpinner";
import { PendingOperationState } from "./PendingOperation";
import "./PendingOperationStatus.scss";

interface PendingOperationStatusProps {
  op: PendingOperationState;
  inline?: boolean;
  pendingTxt?: string;
  successTxt?: string;
  errorTxt?: string;
  hideValidationDetails?: boolean;
}

export function PendingOperationStatus(props: PendingOperationStatusProps) {
  if (!props.op) return null;

  const op = props.op;
  const cls = toClassNames({
    "pending-operation-status": true,
    inline: props.inline,
  });

  if (op.pending) {
    return (
      <div className={cls}>
        <FeedbackMessage kind="info">
          <Text>{props.pendingTxt || tx("Trwa zapisywanie")}</Text>
          &nbsp;
          <LoadingSpinner />
        </FeedbackMessage>
      </div>
    );
  } else if (op.error) {
    if (props.hideValidationDetails && op.validation) {
      return (
        <div className={cls}>
          <FeedbackMessage kind="error">Błędy walidacji</FeedbackMessage>
        </div>
      );
    }

    return (
      <div className={cls}>
        <FeedbackMessage kind="error">
          {op.errorMessage || <Text>{props.errorTxt}</Text>}
          {op.technicalError && <span> (tech: {op.technicalError})</span>}
        </FeedbackMessage>
      </div>
    );
  } else if (op.success) {
    return (
      <div className={cls}>
        <FeedbackMessage kind="success">{props.successTxt || "Zapisano"}</FeedbackMessage>
      </div>
    );
  } else {
    return null;
  }
}
