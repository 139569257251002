import { ValidationError } from "common/errorTypes";

export interface ValidationEntry {
  field?: string;
  message: string;
}

export interface ValidationObjectDataValue {
  field: string;
  messages: ValidationEntry[];
  aggregatedMessage: string;
}

export interface ValidationObjectData {
  [prop: string]: ValidationObjectDataValue;
}

export function createValidationObjectDataFromServerResponse(res: ValidationError): ValidationObjectData {
  const result: ValidationObjectData = {};

  for (let m of res.validationMessages || []) {
    if (!m.property) continue;
    const propertyLower = m.property.toLowerCase();

    let value = result[propertyLower];
    if (!value) {
      value = {
        field: propertyLower,
        messages: [m],
        aggregatedMessage: m.message
      };
      result[propertyLower] = value;
    }
    else {
      value.messages.push(m);
      value.aggregatedMessage = value.messages.map(x => x.message).join('\n');
    }
  }

  return result;

}
