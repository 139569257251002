import React from "react";
import {
  ItemDisplayFn,
  ItemFiltering,
  ItemKeyFn,
  ItemRenderFn,
  ItemSearchValueFn,
  selectUtils,
} from "../utils/selectUtils";
import { Placement } from "popper.js";
import { ClickableIcon, combineClassNames, Icon, KeyCodes } from "core/utils";
import { MultiselectView } from "./MultiselectView";
import { MutliselectSelectedItems } from "./MutliselectSelectedItems";
import { TrCtx, TrFunction } from "core/intl";

interface MultiselectProps<TItem> extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  value: any[] | undefined;
  items: TItem[];
  onChange: (value: TItem[]) => any;
  itemKey?: keyof TItem | ItemKeyFn<TItem>;
  itemKeyAsModel?: boolean;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  searchValue?: keyof TItem | ItemSearchValueFn<TItem>;
  itemRender?: ItemRenderFn<TItem>;
  placement?: Placement;
  sortOnChange?: boolean;
  selectedText?: string;
  chipsInline?: boolean;
  hideChips?: boolean;
  onKeyDown?: (ev: React.KeyboardEvent) => any;
  filtering?: ItemFiltering<TItem>;
  noResultMessage?: string;
  errorAnnotation?: string;
}

interface MultiselectState {
  inputValue: string;
  isInputFocused: boolean;
  isDirty: boolean;
  filterToken: string;
  multiselectVisible: boolean;
}

export class Multiselect<TItem> extends React.Component<MultiselectProps<TItem>, MultiselectState> {
  static rootClassName = "n-multiselect";
  static defaultPlacement: Placement = "bottom-start";

  multiselectView?: MultiselectView<TItem>;
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: MultiselectProps<TItem>) {
    super(props);

    this.state = {
      isInputFocused: false,
      isDirty: false,
      inputValue: "",
      filterToken: "",
      multiselectVisible: false,
    };

    this.onKeydown = this.onKeydown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
    this.clear = this.clear.bind(this);
    this.hide = this.hide.bind(this);
  }

  render() {
    const {
      value,
      className,
      items,
      selectedText,
      chipsInline,
      hideChips,
      onChange,
      itemKey,
      itemKeyAsModel,
      display,
      searchValue,
      itemRender,
      placement,
      sortOnChange,
      children,
      filtering,
      errorAnnotation,
      placeholder,
      ...restProps
    } = this.props;
    const classNames = combineClassNames(className, Multiselect.rootClassName, errorAnnotation && "has-error");
    const helperIcon = this.renderClearOrAngleDownIcon();
    const clsWrapper = combineClassNames(
      "input-wrapper",
      Multiselect.rootClassName,
      helperIcon ? "has-helper-icon" : undefined,
      this.state.isInputFocused && "focused",
      this.props.disabled && "disabled",
      value?.length ? "filled" : "empty"
    );

    return (
      <TrCtx>
        {tr => (
          <>
            <div className={clsWrapper}>
              <input
                {...restProps}
                className={classNames}
                type="text"
                placeholder={placeholder && tr(placeholder)}
                ref={this.inputRef}
                value={this.getViewValue(tr)}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                onKeyDown={this.onKeydown}
                onClick={this.onClick}
              />
              {helperIcon}
              {errorAnnotation && <div className="error-field-annotation">{errorAnnotation}</div>}
              {this.renderMultiselectView()}
            </div>
            {this.renderSelectedItems()}
          </>
        )}
      </TrCtx>
    );
  }

  renderClearOrAngleDownIcon() {
    if (this.props.disabled || this.props.readOnly) return null;

    return this.props.value?.length ? (
      <ClickableIcon className="input-icon" symbol="times" onClick={this.clear} />
    ) : (
      <Icon className="input-icon" symbol="angleDown" />
    );
  }

  renderSelectedItems() {
    if (this.props.hideChips) return null;

    const value = this.props.value;
    if (!value || !value.length) return null;

    return (
      <MutliselectSelectedItems
        items={this.props.items}
        selected={value}
        itemKey={this.props.itemKey}
        itemKeyAsModel={this.props.itemKeyAsModel}
        display={this.props.display}
        onDeselect={this.onItemSelect}
        chipsInline={this.props.chipsInline}
        disabled={this.props.disabled}
      />
    );
  }

  renderMultiselectView() {
    if (!this.state.multiselectVisible) return undefined;

    const placement = this.props.placement || Multiselect.defaultPlacement;

    return (
      <MultiselectView
        searchText={this.state.filterToken}
        searchValue={this.props.searchValue}
        filtering={this.props.filtering}
        display={this.props.display}
        items={this.props.items}
        itemKey={this.props.itemKey}
        itemKeyAsModel={this.props.itemKeyAsModel}
        itemRender={this.props.itemRender}
        selected={this.props.value}
        onSelect={this.onItemSelect}
        placement={placement}
        popoverRef={this.inputRef.current!}
        onOutsideClick={this.hide}
        noResultMessage={this.props.noResultMessage}
        ref={view => (this.multiselectView = view || undefined)}
      />
    );
  }

  onFocus(ev: React.FocusEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    this.setState({
      isInputFocused: true,
      isDirty: false,
      inputValue: ev.target.value,
      filterToken: "",
    });
    this.inputRef.current && this.inputRef.current.select();
    this.props.onFocus && this.props.onFocus(ev);
  }

  onBlur(ev: React.FocusEvent<HTMLInputElement>) {
    this.setState({
      isInputFocused: false,
      isDirty: false,
      inputValue: "",
      filterToken: "",
      multiselectVisible: false,
    });
    this.props.onBlur && this.props.onBlur(ev);
  }

  onClick(ev: React.MouseEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    ev.stopPropagation();
    ev.preventDefault();
    ev.nativeEvent.stopImmediatePropagation();
    if (this.show()) {
      this.inputRef.current && this.inputRef.current.select();
    } else {
      this.hide();
    }
    this.props.onClick && this.props.onClick(ev);
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const filterToken = ev.target.value;

    if (!this.state.multiselectVisible) this.show();
    this.setState({
      filterToken,
      inputValue: ev.target.value,
      isDirty: true,
    });
  }

  onKeydown(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (this.props.readOnly) return;

    switch (ev.keyCode) {
      case KeyCodes.downarrow:
      case KeyCodes.uparrow:
        ev.preventDefault();
        ev.stopPropagation();
        this.multiselectView && this.multiselectView.onKeydown(ev);
        if (!this.multiselectView) this.show();
        break;

      case KeyCodes.enter:
      case KeyCodes.space:
        this.multiselectView && this.multiselectView.onKeydown(ev);
        break;

      case KeyCodes.escape:
        if (this.state.multiselectVisible) this.hide();
        break;

      default:
        break;
    }

    this.props.onKeyDown && this.props.onKeyDown(ev);
  }

  formatItemDisplay(item: any) {
    return selectUtils.formatItemDisplay(item, this.props.display);
  }

  getItemKey(item: any) {
    return selectUtils.getItemKey(item, this.props.itemKey);
  }

  onItemSelect(item: TItem) {
    let newModel = this.getValueFullItems().slice();

    const key = this.getItemKey(item);
    const existingItem = newModel.filter(x => this.getItemKey(x) === key)[0];
    if (existingItem !== undefined) {
      const idx = newModel.indexOf(existingItem);
      newModel.splice(idx, 1);
    } else {
      newModel.push(item);
    }

    if (this.props.sortOnChange) newModel.sort();
    this.props.onChange && this.props.onChange(newModel);
  }

  clear(ev?: React.MouseEvent) {
    if (this.props.disabled) return;

    this.props.onChange([]);
  }

  show() {
    if (this.state.multiselectVisible) return false;

    this.setState({
      multiselectVisible: true,
    });
    return true;
  }

  hide() {
    if (!this.state.multiselectVisible) return;

    this.setState({
      multiselectVisible: false,
    });
  }

  toggle() {
    this.setState({
      multiselectVisible: !this.state.multiselectVisible,
    });
  }

  getValueFullItems(): TItem[] {
    let values = this.props.value || [];
    if (this.props.itemKeyAsModel) {
      values = values
        .map(key => this.props.items.find(x => this.getItemKey(x) === key))
        .filter(item => {
          if (item === undefined) {
            console.warn("Could not find multiselect item by key");
          }

          return item !== undefined;
        });
    }

    return values;
  }

  getViewValue(tr: TrFunction) {
    if (this.state.isInputFocused && this.state.isDirty) {
      return this.state.inputValue;
    } else {
      return this.formatModelDisplay(tr);
    }
  }

  formatModelDisplay(tr: TrFunction): string {
    const { value } = this.props;

    if (!value || !value.length) {
      return "";
    } else {
      return this.props.selectedText
        ? `${tr(this.props.selectedText)} : ${value.length}`
        : tr("Wybrano {{selectedCount}}", { selectedCount: value.length });
    }
  }
}
