import { HelpIcon } from "core/utils";
import React, { CSSProperties, useContext } from "react";
import { useTranslation } from "react-i18next";
import { combineClassNames } from "../utils/reactHelpers";
import { FormContext } from "./withContext";

interface FormItemProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  labelClassName?: string;
  className?: string;
  requiredAsterix?: boolean;
  helpText?: string;
  disabled?: boolean;
  hideIf?: any;
  noTranslate?: boolean;
  style?: CSSProperties;
}

export function FormItem(props: FormItemProps) {
  const form = useContext(FormContext);
  const { t } = useTranslation();
  const lblClassName = combineClassNames("label", props.labelClassName);
  const disabledValue = props.hasOwnProperty("disabled") ? props.disabled : form.disabled;
  const clsName = combineClassNames("form-item", props.className, disabledValue && "disabled");
  const labelToRender = typeof props.label === "string" && !props.noTranslate ? t(props.label) : props.label;

  if (props.hideIf) return null;

  return (
    <div className={clsName} style={props.style}>
      {props.label && (
        <div className={lblClassName}>
          {labelToRender} {props.helpText && <HelpIcon tooltipText={props.helpText} />}{" "}
          {props.requiredAsterix && <span className="required-asterix">*</span>}
        </div>
      )}
      <div>{props.children}</div>
    </div>
  );
}
