import { Text } from "core/content";
import { useTr } from "core/intl";
import React, { useEffect, useState } from "react";
import { combineClassNames } from "../utils";
import "./SwitchSlider.scss";

interface SwitchSliderProps {
  value: boolean;
  onChange: (newValue: boolean) => any;
  showPendingAfterChangeUntilValueMatch?: boolean;
  pendingOperation?: boolean;
  disabled?: boolean;
  title?: string;
  children?: React.ReactNode;
  inline?: boolean;
  labelFirst?: boolean;
  noTranslate?: boolean;
}

export function SwitchSlider(props: SwitchSliderProps) {
  const { value } = props;
  const [expectedPendingValue, setExpectedPendingValue] = useState<boolean>();
  const tr = useTr();

  useEffect(() => {
    if (expectedPendingValue === undefined) return;

    if (expectedPendingValue === value) {
      setExpectedPendingValue(undefined);
    }
  }, [expectedPendingValue, value]);

  const toggle = () => {
    if (props.disabled) return;
    if (props.pendingOperation) return;
    if (expectedPendingValue !== undefined && expectedPendingValue !== props.value) return;

    props.onChange(!props.value);
    if (props.showPendingAfterChangeUntilValueMatch) {
      setExpectedPendingValue(!props.value);
    }
  };

  const rootCls = combineClassNames(
    "switch-slider",
    props.disabled ? "disabled" : undefined,
    props.inline ? "inline" : undefined
  );
  const sliderCls = combineClassNames("slider", props.value ? "on" : "off");
  const labelText = props.children && <Text className="label-text">{props.children}</Text>;
  const showPending = props.pendingOperation || expectedPendingValue !== undefined;
  const titleToRender = typeof props.title === 'string' ? tr(props.title) : props.title;

  return (
    <label onClick={toggle} className={rootCls} title={titleToRender}>
      {props.labelFirst && labelText}
      {showPending ? (
        <span className="pending-icon-container">
          <i className="fas fa-spinner fa-spin"></i>
        </span>
      ) : (
        <span className={sliderCls}></span>
      )}
      {!props.labelFirst && labelText}
    </label>
  );
}
