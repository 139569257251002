import React from "react";
import "./HelpIcon.scss";
import { IconSymbol, Icon } from "./Icon";
import { Tooltip } from "core/content";
import { Placement } from "popper.js";
import { useTr } from "core/intl";

interface HelpIconProps {
  children?: React.ReactNode;
  symbol?: IconSymbol;
  tooltipText: string;
  placement?: Placement;
  color?: string;
}

export function HelpIcon(props: HelpIconProps) {
  const { children, symbol, tooltipText, placement, color } = props;
  const iconSymbol = symbol || "infoCricle";
  const tr = useTr();

  return (
    <Tooltip content={tr(tooltipText)} placement={placement || "auto"}>
      <span className="help-icon">
        {children}
        {children ? <span>&nbsp;</span> : null}
        <Icon symbol={iconSymbol} color={color}/>
      </span>
    </Tooltip>
  );
}
