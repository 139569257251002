import { DatepickerInput, TimepickerInput } from "core/forms";
import React from "react";

interface DateTimeInputProps<TModel> {
  value: TModel | undefined;
  onChange: (value?: TModel) => any;
  modelFormat?: string;
  dateViewFormat?: string;
  timeViewFormat?: string;
  disabled?: boolean;
  errorAnnotation?: string;
  datePlaceholder?: string;
  inlineBlock?: boolean;
}

export function DateTimeInput<TModel = number>(props: DateTimeInputProps<TModel>) {
  const { value, onChange, disabled, dateViewFormat, timeViewFormat, errorAnnotation, datePlaceholder, inlineBlock } =
    props;
  const modelFormat = props.modelFormat || "unix";
  const hasError = Boolean(errorAnnotation);

  return (
    <div className="datetime-input" style={{ display: inlineBlock ? "inline-block" : undefined }}>
      <DatepickerInput
        modelFormat={modelFormat}
        viewFormat={dateViewFormat}
        className="with-timepicker"
        value={value}
        onChange={onChange}
        disabled={disabled}
        hasError={hasError}
        placeholder={datePlaceholder}
      />
      <TimepickerInput
        modelFormat={modelFormat}
        viewFormat={timeViewFormat}
        value={value}
        onChange={onChange}
        disabled={disabled}
        hasError={hasError}
      />
      {errorAnnotation && <div className="error-field-annotation">{errorAnnotation}</div>}
    </div>
  );
}
