import React from "react";
import { useTranslation } from "react-i18next";
import { toClassNames } from "../utils/reactHelpers";
import "./Text.scss";

interface TextProps extends React.HTMLAttributes<HTMLElement> {
  block?: boolean;
  bold?: boolean;
  muted?: boolean;
  danger?: boolean;
  warning?: boolean;
  success?: boolean;
  lineThrough?: boolean;
  small?: boolean;
  center?: boolean;
  right?: boolean;
  wordBreakAll?: boolean;
  overline?: boolean;
  overlineCaps?: boolean;
  noTranslate?: boolean;
  trContext?: string;
}

export function Text(props: TextProps) {
  const {
    className,
    small,
    bold,
    muted,
    block,
    lineThrough,
    danger,
    warning,
    success,
    center,
    right,
    wordBreakAll,
    overline,
    overlineCaps,
    title,
    children,
    noTranslate,
    ...restProps
  } = props;

  const cls = toClassNames({
    [className || ""]: true,
    "font-weight-bold": bold,
    "text-muted": muted,
    small: small,
    "text-danger": danger,
    "text-warning": warning,
    "text-success": success,
    "text-center": center,
    "text-right": right,
    overline: overline,
    "overline-caps": overlineCaps,
    "word-break-all": wordBreakAll,
    "line-through": lineThrough,
  });
  const { t } = useTranslation();

  const titleToRender = typeof title === "string" && !noTranslate ? t(title) : title;
  const childrenToRender = typeof children === "string" && !noTranslate ? t(children) : children;

  if (block) {
    return (
      <div className={cls || undefined} title={titleToRender} {...restProps}>
        {childrenToRender}
      </div>
    );
  } else {
    return (
      <span className={cls || undefined} title={titleToRender} {...restProps}>
        {childrenToRender}
      </span>
    );
  }
}
