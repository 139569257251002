import React from "react";
import "./MessageDialog.scss";
import { withWindowContext, InjectedWindowContext } from "../WindowViewContext";
import { Grid, GridItem } from "../../layout/Grid";
import { Button } from "../../forms";

interface MessageDialogProps extends InjectedWindowContext {
  title: string;
  message: string;
}

export const MessageDialog = withWindowContext(
  class MessageDialog extends React.Component<MessageDialogProps> {
    static title = "Informacja";
    static icon = "fas fa-info";

    close = () => this.props.window.close();

    render() {
      return (
        <Grid className="message-dialog">
          <GridItem rowTemplate="1fr" scrollable>
            <div className="message">{this.props.message}</div>
          </GridItem>

          <GridItem rowTemplate="auto" className="bottom-panel">
            <Button onClick={this.close}>OK</Button>
          </GridItem>
        </Grid>
      );
    }

    componentDidMount() {
      this.props.window.setTitle(this.props.title);
      this.props.window.setSize([400, 200]);
    }
  }
);
