import { combineClassNames, HelpIcon, Icon, SortingOrder } from "core/utils";
import React from "react";
import { useTranslation } from "react-i18next";

interface ThProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  width?: string | number;
  minWidth?: string | number;
  noTranslate?: boolean;
  helpText?: string;
  activeSorting?: SortingOrder;
}

export function Th(props: ThProps) {
  const { width, minWidth, children, style, noTranslate, helpText, className, activeSorting, ...restProps } = props;
  const clickable = Boolean(props.onClick);
  const { t } = useTranslation();

  const finalStyles = Object.assign({}, style || {});
  if (width) {
    finalStyles.width = width;
  }

  if (minWidth) {
    finalStyles.minWidth = minWidth;
  }

  const cls = combineClassNames(className, clickable ? "clickable" : undefined);
  const childrenToRender = typeof children === "string" && !noTranslate ? t(children) : children;

  return (
    <th style={finalStyles} className={cls} {...restProps}>
      {childrenToRender}
      {activeSorting === "asc" && <Icon symbol="arrowDownShortWide" />}
      {activeSorting === "desc" && <Icon symbol="arrowDownWideShort" />}
      {helpText && (
        <>
          &nbsp;
          <HelpIcon tooltipText={helpText} />
        </>
      )}
    </th>
  );
}
