// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { IdLabel, UserDesktopStateMetadata, UserDesktopState } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getAllIdLabelForCurrentUser: 'api/UserDesktopState/GetAllIdLabelForCurrentUser',
  getMetadataList: 'api/UserDesktopState/GetMetadataList',
  markForRestoreAfterLogin: 'api/UserDesktopState/MarkForRestoreAfterLogin',
  getById: 'api/UserDesktopState/GetById',
  createOrUpdate: 'api/UserDesktopState/CreateOrUpdate',
  removeById: 'api/UserDesktopState/RemoveById',
};

function getAllIdLabelForCurrentUser(): Promise<IdLabel[]> {
  return axios.request<IdLabel[]>({
    url: paths.getAllIdLabelForCurrentUser,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function getMetadataList(): Promise<UserDesktopStateMetadata[]> {
  return axios.request<UserDesktopStateMetadata[]>({
    url: paths.getMetadataList,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface MarkForRestoreAfterLoginParams {
  desktopStateId?: number;
  restoreAfterLogin?: boolean;
}

function markForRestoreAfterLogin(params: MarkForRestoreAfterLoginParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.markForRestoreAfterLogin,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

interface GetByIdParams {
  id: number;
}

function getById(params: GetByIdParams): Promise<UserDesktopState> {
  return axios.request<UserDesktopState>({
    url: paths.getById,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function createOrUpdate(data: UserDesktopState): Promise<UserDesktopState> {
  return axios.request<UserDesktopState>({
    url: paths.createOrUpdate,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

interface RemoveByIdParams {
  id: number;
}

function removeById(params: RemoveByIdParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.removeById,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

export const userDesktopStateService = {
  paths,
  getAllIdLabelForCurrentUser,
  getMetadataList,
  markForRestoreAfterLogin,
  getById,
  createOrUpdate,
  removeById,
};
