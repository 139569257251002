import React, { useContext } from "react";
import { FormContext } from "./FormContext";
import { DayMinutesOffsetInput } from "../controls/DayMinutesOffsetInput";
import { useValidationForField } from "core/validation";

type DayMinutesOffsetInputProps = React.ComponentProps<typeof DayMinutesOffsetInput>;

interface DayMinutesOffsetFieldProps extends Omit<DayMinutesOffsetInputProps, "value" | "onChange"> {
  name: string;
}

export const DayMinutesOffsetField = React.memo(function DayMinutesOffsetField(props: DayMinutesOffsetFieldProps) {
  const form = useContext(FormContext);
  const { name, ...restProps } = props;
  const value = form.model[props.name];
  const validation = useValidationForField(name);

  return (
    <DayMinutesOffsetInput
      {...restProps}
      onChange={value => form.handleFieldChange(props.name, value)}
      value={value}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
      errorAnnotation={validation?.aggregatedMessage}
    />
  );
});
