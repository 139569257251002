import React from "react";
import { combineClassNames } from "../utils/reactHelpers";

interface PullRightProps extends React.HTMLAttributes<HTMLSpanElement> {}

export function PullRight(props: PullRightProps) {
  const { className, ...restProps } = props;
  const cls = combineClassNames(className, "pull-right");

  return <span className={cls} {...restProps} />;
}
