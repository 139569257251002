import { useEffect, useState } from "react";
import "./App.scss";
import { eventBus } from "./core/events/eventBus";
import securityService from "./core/auth/securityService";
import { systemState } from "./core/global/systemState";
import { MainRouter } from "./router/MainRouter";
import {
  SystemSettingsChangedEvent,
  TranslationsChangedEvent,
  UserLoggedOutEvent,
  UserSettingsChangedEvent,
} from "./common/eventBusTypes";
import { LoginPage } from "./core/auth/LoginPage";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { LogoWatermark } from "core/utils/LogoWatermark";
import { useDelayedEffect } from "core/utils";

export function App() {
  const loggedUserUpdated = useLoggedUserUpdated();
  const isLoggedIn = systemState.useGlobalState(gs => Boolean(gs.user));
  const minLoadingTimeElapsed = useMinLoadingTimeElapsed();
  const isBootstrapping = !minLoadingTimeElapsed || !loggedUserUpdated;

  eventBus.useEvent("apiResponse401", () => {
    if (isLoggedIn) {
      securityService.updateLoggedUser();
    }
  });
  eventBus.useEvent<SystemSettingsChangedEvent>("systemSettingsChanged", () => securityService.updateAppConfig());
  eventBus.useEvent<UserSettingsChangedEvent>("userSettingsChanged", () => securityService.updateAppConfig());
  eventBus.useEvent<UserLoggedOutEvent>("userLoggedOut", ev => {
    systemState.setLastLogoutReason(ev.reason);
    securityService.logout();
  });
  eventBus.useEvent<TranslationsChangedEvent>("translationsChanged", ev => {
    i18next.reloadResources();
  });

  return <div id="app">{isBootstrapping ? <BootstrapInfo /> : isLoggedIn ? <MainRouter /> : <LoginPage />}</div>;
}

function useLoggedUserUpdated(): boolean {
  const [loggedUserUpdated, setLoggedUserUpdated] = useState(false);

  useEffect(() => {
    securityService.updateLoggedUser().then(() => setLoggedUserUpdated(true));
  }, []);

  return loggedUserUpdated;
}

function useMinLoadingTimeElapsed(): boolean {
  const [minLoadingTimeElapsed, setMinLoadingTimeElapsed] = useState(false);
  useDelayedEffect(() => setMinLoadingTimeElapsed(true), 500);

  return minLoadingTimeElapsed;
}

function BootstrapInfo() {
  const { t, ready } = useTranslation();

  return (
    <div className="bootstrap">
      <LogoWatermark annotation={ready ? (t("Wczytywanie aplikacji...") as any) : undefined} />
    </div>
  );
}
