import { systemState } from "core/global";
import { FileMetadata } from "../../apinet/models";
import { FileViewWindow } from "../../domain/files/FileViewWindow";
import desktopManager from "../desktop/desktopManager";

export const fileOpener = {
  openById,
  openByIdInNewWindow,
  open,
};

function openById(fileId: number) {
  desktopManager.openWindow({
    component: FileViewWindow,
    props: {
      fileId,
    },
  });
}

function open(file: FileMetadata) {
  openById(file.id);
}

function openByIdInNewWindow(fileId: number) {
  if (systemState.state.isPresentationModeActive) return;
  
  window.open(`api/files/${fileId}`);
}
