import React from "react";
import { FormContext } from "./FormContext";
import { KeyCodes } from "../../utils/keyCodeMap";
import { Multiselect } from "core/forms";
import { ItemDisplayFn, ItemKeyFn, ItemRenderFn } from "core/utils";

type MultiselectProps = React.ComponentProps<typeof Multiselect>;

interface MultiselectFieldProps<TItem>
  extends Omit<MultiselectProps, "value" | "onChange" | "items" | "itemKey" | "display" | "itemRender"> {
  name: string;
  items: TItem[];
  itemKey?: keyof TItem | ItemKeyFn<TItem>;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  itemRender?: ItemRenderFn<TItem>;
}

export class MultiselectField<TItem> extends React.PureComponent<MultiselectFieldProps<TItem>> {
  static contextType = FormContext;
  declare context: React.ContextType<typeof FormContext>;

  constructor(props: MultiselectFieldProps<TItem>) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  render() {
    const props = this.props;
    const { name, ...restProps } = props;
    const value = this.context.model[props.name];
    const form = this.context;

    return (
      <Multiselect
        value={value}
        {...restProps}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
      />
    );
  }

  onChange(newValue: any[]) {
    if (this.props.itemKeyAsModel) {
      newValue = newValue.map(x => this.getItemKey(x));
    }
    this.context.handleFieldChange(this.props.name, newValue);
  }

  onKeyDown(ev: React.KeyboardEvent) {
    if (ev.keyCode === KeyCodes.enter) {
      this.context.handleSubmit();
    }
  }

  getItemKey(item: any) {
    if (typeof item === "string") return item;
    if (typeof item === "number") return item;

    return item[this.props.itemKey || "id"];
  }
}
