import { Text } from "core/content";
import React from "react";
import { combineClassNames } from "../../utils";
import "./Radio.scss";

interface RadioProps {
  selected: boolean | undefined;
  onClick: () => any;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export class Radio extends React.PureComponent<RadioProps> {
  constructor(props: RadioProps) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const props = this.props;
    const cls = combineClassNames(
      "radio",
      props.className,
      props.disabled ? "disabled" : undefined
    );

    const inputRadio = (
      <input
        type="radio"
        onChange={this.onChange}
        checked={props.selected}
        style={props.style}
        disabled={props.disabled}
      />
    );

    return props.children ? (
      <label className={cls}>
        {inputRadio}
        <Text>{props.children}</Text>
      </label>
    ) : (
      inputRadio
    );
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    this.props.onClick();
  }
}
