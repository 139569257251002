import { combineClassNames, useScrolledTop } from "core/utils";
import React from "react";
import "./ShadowScrollableContainer.scss";

interface ShadowScrollableContainerProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
}

export function ShadowScrollableContainer(props: ShadowScrollableContainerProps) {
  const { children, className, style, onScroll } = props;
  const [isScrolledToTop, onScrollTopHandler] = useScrolledTop();

  const onDivScroll: React.UIEventHandler<HTMLDivElement> = ev => {
    onScrollTopHandler(ev);
    onScroll && onScroll(ev);
  };

  const cls = combineClassNames("actual-scrollable-content-container", className);

  return (
    <div className="shadow-scrollable-container">
      <div className={cls} style={style} onScroll={onDivScroll}>
        {children}
      </div>
      {!isScrolledToTop && <div className="shadow-source-element"></div>}
    </div>
  );
}
