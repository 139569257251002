import desktopManager from "../desktopManager";
import { YesNoDialog } from "./YesNoDialog";
import { VerifyUserDialog } from "./VerifyUserDialog";
import { MessageDialog } from "./MessageDialog";
import { tx } from "core/intl";

export const generalDialogs = {
  yesNo,
  deleteConfirm,
  verifyUserWithPassword,
  message,
};

interface YesNoArgs {
  title: string;
  question: string;
  yesLabel?: string;
  noLabel?: string;
}

interface DeleteConfirmArgs {
  question: string;
  title?: string;
  yesLabel?: string;
  noLabel?: string;
}

interface VerifyUserWithPasswordArgs {
  confirmLabel?: string;
  operationDescription?: string;
}

interface MessageArgs {
  title: string;
  message: string;
}

function deleteConfirm(args: DeleteConfirmArgs) {
  return yesNo({
    question: args.question,
    title: args.title || tx("Potwierdzenie usunięcia"),
    yesLabel: args.yesLabel || tx("Usuń"),
    noLabel: args.noLabel || tx("Nie usuwaj"),
  });
}

function yesNo(args: YesNoArgs) {
  const wnd = desktopManager.openWindow({
    component: YesNoDialog,
    props: {
      title: args.title,
      question: args.question,
      yesLabel: args.yesLabel,
      noLabel: args.noLabel,
    },
  });

  return {
    onYes: (onYesHandler: () => any) => {
      wnd.onClose((res) => {
        if (res) onYesHandler();
      });
    },
    onNo: (onNoHandler: () => any) => {
      wnd.onClose((res) => {
        if (!res) onNoHandler();
      });
    },
  };
}

function verifyUserWithPassword(
  args?: VerifyUserWithPasswordArgs
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    desktopManager
      .openWindow({
        component: VerifyUserDialog,
        props: {
          confirmLabel: args?.confirmLabel,
          operationDescription: args?.operationDescription,
        },
      })
      .onClose((res) => {
        if (res) {
          resolve();
        } else {
          reject();
        }
      });
  });
}

function message(args: MessageArgs) {
  const wnd = desktopManager.openWindow({
    component: MessageDialog,
    props: {
      title: args.title,
      message: args.message,
    },
  });

  return wnd;
}
