import { combineClassNames } from "core/utils";
import React from "react";

interface SimpleContainerProps {
  children: React.ReactNode;
  padding?: boolean | string;
  scrollable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  hidden?: boolean;
}

export class SimpleContainer extends React.PureComponent<SimpleContainerProps> {
  render() {
    const style: React.CSSProperties = {
      height: "100%",
    };

    if (this.props.padding) {
      style.padding =
        typeof this.props.padding === "string" ? this.props.padding : "16px";
      style.boxSizing = "border-box";
    }

    if (this.props.style) {
      Object.assign(style, this.props.style);
    }

    const cls = combineClassNames(
      this.props.className,
      this.props.scrollable ? "scrollable" : undefined
    );

    return (
      <div
        style={style}
        className={cls}
        onScroll={this.props.onScroll}
        hidden={this.props.hidden}
      >
        {this.props.children}
      </div>
    );
  }
}
