import { GlobalState } from "react-gstate";
import { systemState } from ".";
import { SideMenuEdgeTriggerBehaviour } from "apinet/models";

export interface StartMenuState {
  show: boolean;
}

class StartMenuStore extends GlobalState<StartMenuState> {
  hide() {
    if (!this.state.show) return;

    this.setState({
      show: false,
    });
  }

  show() {
    if (this.state.show) return;

    this.setState({
      show: true,
    });
  }

  toggle() {
    this.setState({
      show: !this.state.show,
    });
  }

  isVisible() {
    return this.state.show;
  }
}

export const startMenuState = new StartMenuStore({
  show: false,
});

const leftOsKeyCode = 91;
const shiftKeyCode = 16;
const tildeKeyCode = 192;
const leftZoneReactionZoneWidth = 10;
const altTriggers = [leftOsKeyCode, shiftKeyCode];

window.addEventListener("keydown", ev => {
  if (ev.keyCode === tildeKeyCode || (ev.altKey && altTriggers.includes(ev.keyCode))) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    startMenuState.toggle();
  }
});

window.addEventListener("mousemove", ev => {
  if (ev.buttons !== 0) return;
  if (startMenuState.state.show) return;

  const cfg = systemState.state.config;
  if (!cfg) return;

  switch (cfg.userConfig.sideMenuEdgeTriggerBehaviour) {
    case SideMenuEdgeTriggerBehaviour.ActivateOnEdgeMargin:
      if (ev.movementX < 0 && ev.clientX <= leftZoneReactionZoneWidth) {
        startMenuState.show();
      }
      break;

    case SideMenuEdgeTriggerBehaviour.ActivateOnStrictEdge:
      if (ev.movementX < 0 && ev.x === 0) {
        startMenuState.show();
      }
      break;

    default:
      break;
  }
});
