import React from "react";
import "./NativeModalWindow.scss";
import {
  WindowViewContext,
  IWindowViewContext,
} from "../../core/desktop/WindowViewContext";
import { debounce } from "../../core/utils/utils";
import { Subject } from "rxjs";
import { WindowInstance } from "../../core/desktop/Window";
import { desktopState } from "../../core/global/desktopState";
import { WindowView } from "../../core/desktop/WindowView";
import { systemState } from "../../core/global/systemState";

interface NativeModalWindowProps {
  children: () => React.ReactNode;
}

interface NativeModalWindowState {
  initialized: boolean;
  windows: WindowInstance[];
  wndContext: IWindowViewContext;
}

function noop() {}

export class NativeModalWindow extends React.Component<
  NativeModalWindowProps,
  NativeModalWindowState
> {
  constructor(props: NativeModalWindowProps) {
    super(props);

    systemState.setNativeWindowMode(true);
    this.close = this.close.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setTitleExtension = this.setTitleExtension.bind(this);

    this.state = {
      ...desktopState.connect(this, (gs) => ({ windows: gs.windows })),
      initialized: false,
      wndContext: {
        maximize: this.maximize,
        maximizeOnInit: this.maximize,
        setTitle: this.setTitle,
        setTitleExtension: this.setTitleExtension,
        setInitSize: this.setSize,
        setSize: this.setSize,
        setMinSize: this.setMinSize,
        setInitPosition: this.setPosition,
        setPosition: this.setPosition,
        activate: this.activateWindow,
        close: this.close,
        setContentComponentSerializedState: noop,
        dragging: false,
        onResize: new Subject(),
        onClick: new Subject(),
        onActivate: new Subject(),
      },
    };
  }

  render() {
    return (
      <WindowViewContext.Provider value={this.state.wndContext}>
        <div className="native-modal-window">
          <div className="content">
            {this.state.initialized && this.props.children()}
          </div>
          {this.renderWindows()}
        </div>
      </WindowViewContext.Provider>
    );
  }

  onBrowserWindowResize = debounce(() => {
    this.state.wndContext.onResize.next();
  }, 300);

  renderWindows() {
    return this.state.windows.map((x) => (
      <WindowView key={x.id} windowInstance={x} />
    ));
  }

  componentDidMount() {
    window.addEventListener("resize", this.onBrowserWindowResize);
    this.setState({
      initialized: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onBrowserWindowResize);
  }

  setTitle(title: string) {
    document.title = title;
  }

  setTitleExtension(titleExtension: string | undefined) {
    // not handled
  }

  setSize(widthHeight: [number, number]) {
    // Browser managed
  }

  setMinSize(widthHeight: [number | undefined, number | undefined]) {
    // Browser managed
  }

  setPosition(leftTop: [number, number]) {
    // Browser managed
  }

  minimize() {
    // Browser managed
  }

  maximize() {
    // Browser managed
  }

  close(result?: any) {
    window.close();
  }

  activateWindow() {
    // Browser managed
  }
}
