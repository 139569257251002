import { SelectInput } from "core/forms";
import { AsyncItemsProvider, ItemDisplayFn } from "core/utils";
import { useValidationForField } from "core/validation";
import React, { useContext } from "react";
import { typedMemo } from "../../utils/reactHelpers";
import { FormContext } from "./FormContext";

type SelectInputProps = React.ComponentProps<typeof SelectInput>;

interface SelectFieldProps<TItem>
  extends Omit<
    SelectInputProps,
    "value" | "onChange" | "items" | "itemKey" | "display" | "itemsRender" | "itemAnnotation"
  > {
  name: string;
  inputName?: string;
  items: TItem[] | AsyncItemsProvider<TItem>;
  itemKey?: keyof TItem;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  itemAnnotation?: keyof TItem | ItemDisplayFn<TItem>;
  onChangeTransform?: (value: TItem | undefined) => any;
}

export const SelectField = typedMemo(function SelectField<TItem>(props: SelectFieldProps<TItem>) {
  const form = useContext(FormContext);
  const { name, inputName, ...restAttrs } = props;
  const validation = useValidationForField(name);
  const value = form.model[name];
  const { itemKey, itemKeyAsModel, onChangeTransform } = props;

  return (
    <SelectInput
      {...restAttrs}
      onChange={value => {
        const valueToSet = onChangeTransform
          ? onChangeTransform(value)
          : itemKeyAsModel
          ? getItemKey(value, itemKey)
          : value;
        form.handleFieldChange(name, valueToSet);
      }}
      value={value}
      name={inputName}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
      errorAnnotation={validation?.aggregatedMessage}
    />
  );
});

function getItemKey(item: any, itemKey: any | undefined) {
  if (item === undefined || item === null) return item;

  if (typeof item === "string") return item;
  if (typeof item === "number") return item;

  return item[itemKey || "id"];
}
