export const stringUtils = {
  capitalizeFirstLetter,
  sortByFirstNumber,
  removePolishChars,
  escapeRegex,
};

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.substring(1);
}

function sortByFirstNumber(arr: string[]) {
  const sortValues: any = {};
  arr.forEach((x) => {
    const sortVal = x.replace(/\d+/, function (found) {
      return found.padStart(10, "0");
    });
    sortValues[x] = sortVal;
  });

  arr.sort((a, b) => {
    const an = sortValues[a];
    const bn = sortValues[b];

    if (an == bn) return 0; // eslint-disable-line
    return an > bn ? 1 : -1;
  });
}

const polishCharsReplacementsMap: Record<string, string> = {
  ę: "e",
  ó: "o",
  ą: "a",
  ś: "s",
  ł: "l",
  ż: "z",
  ź: "z",
  ć: "c",
  ń: "n",
  Ę: "E",
  Ó: "O",
  Ą: "A",
  Ś: "S",
  Ł: "L",
  Ż: "Z",
  Ź: "Z",
  Ć: "C",
  Ń: "N",
};

function removePolishChars(txt: string): string {
  return txt.replace(
    /[ęóąśłżźćń]/gi,
    (m) => polishCharsReplacementsMap[m] || m
  );
}

function escapeRegex(str: string): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
