import { TimepickerInput } from "core/forms";

interface DayMinutesOffsetInputProps {
  value: number | undefined;
  onChange: (value: number | undefined) => any;
  treatMidnightAsNextDay?: boolean;
  disabled?: boolean;
  errorAnnotation?: string;
}

export function DayMinutesOffsetInput(props: DayMinutesOffsetInputProps) {
  const { value, onChange, treatMidnightAsNextDay, ...restProps } = props;
  const d = getDateFromMinutes(value);

  return (
    <TimepickerInput
      {...restProps}
      modelFormat="Date"
      value={d}
      onChange={x => onChange(getMinutesFromDate(x, treatMidnightAsNextDay))}
    />
  );
}

function getDateFromMinutes(minutes: number | undefined): Date | undefined {
  if (minutes === undefined) {
    return undefined;
  }

  return new Date(1970, 1, 1, 0, minutes, 0);
}

function getMinutesFromDate(d: Date | undefined, treatMidnightAsNextDay?: boolean): number | undefined {
  if (d === undefined) {
    return undefined;
  }

  let result = 0;

  if (d) {
    result = d.getHours() * 60 + d.getMinutes();
  }

  if (treatMidnightAsNextDay && result === 0) {
    result = 1440;
  }

  return result;
}
