// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { AppCondition, RequestInfo, EventDependentWork, DbContextModelMetadata } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getAppCondition: 'api/Maintenance/GetAppCondition',
  getActiveRequests: 'api/Maintenance/GetActiveRequests',
  getEventDependentWork: 'api/Maintenance/GetEventDependentWork',
  getDatabaseModel: 'api/Maintenance/GetDatabaseModel',
  runGc: 'api/Maintenance/RunGc',
  cleanOldSystemMetricData: 'api/Maintenance/CleanOldSystemMetricData',
  fixAutoincrementValues: 'api/Maintenance/FixAutoincrementValues',
  assignCityToItcsWithoutCity: 'api/Maintenance/AssignCityToItcsWithoutCity',
};

interface GetAppConditionParams {
  forceRefresh?: boolean;
}

function getAppCondition(params: GetAppConditionParams): Promise<AppCondition> {
  return axios.request<AppCondition>({
    url: paths.getAppCondition,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function getActiveRequests(): Promise<RequestInfo[]> {
  return axios.request<RequestInfo[]>({
    url: paths.getActiveRequests,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function getEventDependentWork(): Promise<EventDependentWork[]> {
  return axios.request<EventDependentWork[]>({
    url: paths.getEventDependentWork,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function getDatabaseModel(): Promise<DbContextModelMetadata[]> {
  return axios.request<DbContextModelMetadata[]>({
    url: paths.getDatabaseModel,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function runGc(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.runGc,
    method: 'get',
    headers: staticRequestHeaders,
  });
}

interface CleanOldSystemMetricDataParams {
  maxIterationCount?: number;
}

function cleanOldSystemMetricData(params: CleanOldSystemMetricDataParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.cleanOldSystemMetricData,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

function fixAutoincrementValues(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.fixAutoincrementValues,
    method: 'post',
    headers: staticRequestHeaders,
  });
}

function assignCityToItcsWithoutCity(): Promise<string[]> {
  return axios.request<string[]>({
    url: paths.assignCityToItcsWithoutCity,
    method: 'post',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

export const maintenanceService = {
  paths,
  getAppCondition,
  getActiveRequests,
  getEventDependentWork,
  getDatabaseModel,
  runGc,
  cleanOldSystemMetricData,
  fixAutoincrementValues,
  assignCityToItcsWithoutCity,
};
