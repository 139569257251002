import { useMemo } from "react";

export function wait(msDelay: number): Promise<void> {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => resolve(), msDelay);
  });
}

export function usePromiseAll(...promises: Array<Promise<any> | undefined>): Promise<any> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => Promise.all(promises), [...promises]);
}
