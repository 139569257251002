export interface ApplicationError {
  name: string;
  message: string;
  status: number;
}

interface ServerValidationMessage {
  property: string;
  message: string;
}

export interface ValidationError extends ApplicationError {
  validationMessages?: ServerValidationMessage[];
}

export interface BusModuleNotEnabled extends ApplicationError {
}

export interface AccessDeniedError extends ApplicationError {
  missingPriv: string;
}

export interface BadRequestError extends ApplicationError {
}

export function isValidationError(arg: any): arg is ValidationError {
  return arg.name === 'ValidationError';
}

export function isAccessDeniedError(arg: any): arg is AccessDeniedError {
  return arg.name === "AccessDeniedError";
}

export function isBadRequestError(arg: any): arg is BadRequestError {
  return arg.name === 'BadRequestError';
}