import React from "react";
import { formatBytes } from "./utils";

interface FileSizeProps {
  bytes: number;
  className?: string;
}

export class FileSize extends React.PureComponent<FileSizeProps> {
  render() {
    return (
      <span className={this.props.className}>
        {this.getFormattedFileSize()}
      </span>
    );
  }

  getFormattedFileSize(): string {
    return formatBytes(this.props.bytes);
  }
}
