import React from "react";
import { PendingOperation, PendingOperationState } from "../utils/PendingOperation";
import { Form } from "../forms/withContext/Form";
import { TextField } from "../forms/withContext/TextField";
import { PasswordField } from "../forms/withContext/PasswordField";
import { SubmitButton } from "../forms/withContext/SubmitButton";
import securityService from "./securityService";
import { systemState } from "../global/systemState";
import { FormItem } from "../forms";
import { FeedbackMessage, Text } from "../content";
import { WithTranslation, withTranslation } from "react-i18next";

interface LoginFormProps extends WithTranslation {
  login?: string;
}

interface LoginFormState {
  model: {
    username: string;
    password: string;
    newPassword: string;
    newPassword2: string;
  };
  requireNewPass: boolean;
  loginPromise?: Promise<any>;
  coolDownActive: boolean;
}

export const LoginForm = withTranslation()(
  class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
    initialModel = {
      username: "",
      password: "",
      newPassword: "",
      newPassword2: "",
    };

    coolDownTimeoutHandle: number | undefined;

    constructor(props: LoginFormProps) {
      super(props);

      this.initialModel.username = props.login || "";

      this.state = {
        model: this.initialModel,
        requireNewPass: false,
        coolDownActive: false,
      };

      this.accept = this.accept.bind(this);
      this.renderView = this.renderView.bind(this);
    }

    componentWillUnmount(): void {
      if (this.coolDownTimeoutHandle) {
        window.clearTimeout(this.coolDownTimeoutHandle);
      }
    }

    render() {
      return <PendingOperation promise={this.state.loginPromise}>{this.renderView}</PendingOperation>;
    }

    renderView(op: PendingOperationState): React.ReactNode {
      const lastLogoutReason = systemState.state.lastLogoutReason;
      const t = this.props.t;

      return (
        <div className="login-form">
          <Form
            rootComponent="form"
            model={this.state.model}
            onChange={model => this.setState({ model })}
            onSubmit={this.accept}
            disabled={op.pending}
          >
            <div className="text-center">
              <h4 className="login-hint">
                <Text>Aby kontynuować, zaloguj się</Text>
              </h4>
            </div>

            {lastLogoutReason && (
              <Text block center danger className="mb-16">
                {lastLogoutReason}
              </Text>
            )}

            <FormItem label="Użytkownik">
              <TextField
                name="username"
                inputName="username"
                autoFocus={!this.props.login}
                placeholder="Nazwa użytkownika"
              />
            </FormItem>
            <FormItem label="Hasło">
              <PasswordField
                name="password"
                inputName="password"
                placeholder="Podaj hasło"
                autoFocus={Boolean(this.props.login)}
              />
            </FormItem>
            {this.renderNewPass()}
            {op.error && <FeedbackMessage kind="error">{op.errorMessage || t("Błąd logowania")}</FeedbackMessage>}
            <div className="text-right">
              <SubmitButton cta big disabled={op.pending || this.state.coolDownActive}>
                {op.pending ? t("Logowanie...") : t("Zaloguj")}
              </SubmitButton>
            </div>
          </Form>
          <div className="vertical-asymetric-form-spacer"></div>
        </div>
      );
    }

    renderNewPass() {
      if (!this.state.requireNewPass) return null;

      return (
        <>
          <FeedbackMessage className="mb-8">Wymagana zmiana hasła:</FeedbackMessage>
          <PasswordField name="newPassword" placeholder="Wpisz nowe hasło" autoFocus className="mb-8" />
          <PasswordField name="newPassword2" placeholder="Powtórz nowe hasło" />
        </>
      );
    }

    accept() {
      if (this.state.coolDownActive) return;

      let promise: Promise<any>;

      systemState.setLastLogoutReason(undefined);

      if (this.state.requireNewPass) {
        promise = this.loginWithPassChange();
      } else {
        promise = this.login().then(res => {
          if (!res.success && res.requirePasswordChange) {
            this.setState({
              requireNewPass: true,
            });
          }
        });
      }

      promise.finally(() => {
        this.setState({
          coolDownActive: true,
        });
        this.coolDownTimeoutHandle = window.setTimeout(() => this.completeCoolDown(), 500);
      });

      this.setState({
        loginPromise: promise,
      });
    }

    completeCoolDown() {
      this.coolDownTimeoutHandle = undefined;
      this.setState({
        coolDownActive: false,
      });
    }

    login() {
      const { username, password } = this.state.model;
      return securityService.login(username, password);
    }

    loginWithPassChange() {
      const { username, password, newPassword, newPassword2 } = this.state.model;
      return securityService.loginWithPassChange(username, password, newPassword, newPassword2);
    }
  }
);
