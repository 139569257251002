import { userDesktopStateService } from "apinet";
import { desktopState } from "../../global/desktopState";
import desktopManager from "../desktopManager";
import { WindowViewSerializedState } from "../WindowView";
import { serializeSupportedWindows } from "./SerializeSupportedWindows";

export interface WindowSerializedState {
  componentName: string;
  componentProps: any;
  title: string;
  minimized: boolean;
  windowView: WindowViewSerializedState;
  contentComponentState?: any;
}

export interface DesktopSerializedState {
  windows: WindowSerializedState[];
}

interface NotSerializableWindow {
  title: string;
}

export const desktopSerializedState = {
  getState,
  restoreState,
  restoreStateById,
  getNonSerializableWindows,
  getWindows() {
    return desktopState.state.windows;
  },
};

(window as any).desktopSerializedState = desktopSerializedState;

function getNonSerializableWindows(): NotSerializableWindow[] {
  return desktopState.state.windows
    .filter((x) => !serializeSupportedWindows.isSupported(x.component))
    .map((x) => ({
      title: x.title,
    }));
}

function getState(): DesktopSerializedState {
  return {
    windows: desktopState.state.windows
      .filter((x) => serializeSupportedWindows.isSupported(x.component))
      .filter((x) => x.windowViewHandlingInstance)
      .map<WindowSerializedState>((x) => {
        const windowViewInstance = x.windowViewHandlingInstance!;

        const result: WindowSerializedState = {
          componentName:
            serializeSupportedWindows.getWindowComponentSerializeName(
              x.component
            ),
          componentProps: x.props,
          title: x.title,
          minimized: x.minimized,
          windowView: windowViewInstance.getWindowViewSerializedState(),
          contentComponentState:
            windowViewInstance.contentComponentSerializedState,
        };
        return result;
      })
      .filter((x) => x.componentName),
  };
}

function restoreState(state: DesktopSerializedState) {
  for (let w of state.windows) {
    const component = serializeSupportedWindows.getComponentBySerializeName(
      w.componentName
    );
    if (!component) {
      console.warn("Nierozpoznany komponent " + w.componentName);
      continue;
    }

    desktopManager.openWindow({
      component,
      props: w.componentProps,
      restoreState: w,
    });
  }
}


function restoreStateById(desktopStateId: number) {
  userDesktopStateService.getById({ id: desktopStateId }).then(res => {
    const state = JSON.parse(res.state) as DesktopSerializedState;
    desktopSerializedState.restoreState(state);
  });
}