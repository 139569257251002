import { defineWindowDetails, useInitWindowSize, useInitiallyMaximizedWindow, useWindowContext } from "core/desktop";
import { systemState } from "core/global";
import { tx } from "core/intl";
import { externalLinkOpenUtils } from "core/utils/externalLinkOpenUtils";
import { useEffect, useRef } from "react";

interface MeasurePointsProxySiteWindowProps {}

defineWindowDetails(MeasurePointsProxySiteWindow, {
  icon: "fas fa-chart-line",
  title: tx("Wykres natężenia ruchu - punkty pomiarowe"),
  isAvailable: ss => Boolean(ss.config && ss.config.trafficPointsProxySiteUrl && ss.privs.itcView),
});

export function MeasurePointsProxySiteWindow(props: MeasurePointsProxySiteWindowProps) {
  useInitWindowSize(800, 600);
  useInitiallyMaximizedWindow();

  const hasOpenRef = useRef(false);
  const wnd = useWindowContext();
  const url = systemState.useGlobalState(ss => ss.config?.trafficPointsProxySiteUrl);

  useEffect(() => {
    if (url && !hasOpenRef.current) {
      hasOpenRef.current = true;
      externalLinkOpenUtils.open(url);
      wnd.close();
    }
  }, [url, wnd]);

  return null;
}
