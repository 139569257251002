import { intlService } from "apinet";
import { Lang } from "apinet/models";
import { useDataLoader } from "core/utils";
import i18next, { InterpolationMap, TOptions } from "i18next";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

/**
 * Translate extract without actually translation logic.
 * Function to only mark text to extract from code but to be translated elsewhere.
 * @param text Text that should be translated.
 * @returns Same text.
 */
export function tx(text: string): string {
  return text;
}

export function globalTr(text: string, interpolationObj?: InterpolationMap<string> & TOptions): string {
  return (interpolationObj ? i18next.t(text, interpolationObj) : i18next.t(text)) || text;
}

export type TrFunction = (text: string, interpolationObj?: InterpolationMap<string> & TOptions) => string;

export function useTr(): TrFunction {
  const { t } = useTranslation();
  return useCallback(
    (text: string, interpolationObj?: InterpolationMap<string> & TOptions) =>
      (interpolationObj ? t(text, interpolationObj) : t(text)) || text,
    [t]
  );
}

interface TrCtxProps<TRet> {
  children: (tr: TrFunction) => TRet;
}

export function TrCtx<TRet>(props: TrCtxProps<TRet>) {
  const tr = useTr();

  return props.children(tr);
}

export function useCurrentLangCodeUpperCase() {
  const i18nextLang = i18next.language;
  return useMemo(() => normalizei18nextCurrentLangToCode(i18nextLang).toUpperCase(), [i18nextLang]);
}

function normalizei18nextCurrentLangToCode(i18nextLang: string) {
  if (!i18nextLang) return "?";
  const match = /^[a-z]+/i.exec(i18nextLang);
  if (match) return match[0].toLowerCase();

  return i18nextLang;
}

export function getCurrentLangCode() {
  return normalizei18nextCurrentLangToCode(i18next.language);
}

export function useLanguagesToSelect(): Lang[] | undefined {
  const includeDevLang = import.meta.env.DEV;

  const [langs] = useDataLoader(() =>
    intlService.getLanguages().then(res => {
      if (res.length || includeDevLang) {
        res.push({
          code: "pl",
          englishName: "Polish",
          name: "Polski",
          nativeName: "Polski",
          published: true,
          id: 0,
        });
      }

      if (includeDevLang) {
        res.push({
          code: "dev",
          englishName: "[SU] Dev",
          name: "[SU] Dev",
          nativeName: "[SU] Dev",
          published: true,
          id: -1,
        });
      }

      return res;
    })
  );

  return langs;
}
