// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { Lang, KeyTranslationImportRequest, KeyTranslationImport, KeyTranslation, KeyTranslationUpdate, KeysUpdateStats } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getLanguages: 'api/Intl/GetLanguages',
  getLangJsonTranslation: 'api/Intl/GetLangJsonTranslation',
  getLanguagesToManage: 'api/Intl/GetLanguagesToManage',
  setPublished: 'api/Intl/SetPublished',
  markKeyAsRemoved: 'api/Intl/MarkKeyAsRemoved',
  prepareKeysFromFile: 'api/Intl/PrepareKeysFromFile',
  getKeysTranslations: 'api/Intl/GetKeysTranslations',
  updateTranslations: 'api/Intl/UpdateTranslations',
  generatePoFile: 'api/Intl/GeneratePoFile',
  generatePotFileFromServerStrings: 'api/Intl/GeneratePotFileFromServerStrings',
  generatePotFileFromServerStringsMergedWithProvidedFile: 'api/Intl/GeneratePotFileFromServerStringsMergedWithProvidedFile',
};

function getLanguages(): Promise<Lang[]> {
  return axios.request<Lang[]>({
    url: paths.getLanguages,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface GetLangJsonTranslationParams {
  langCode?: string;
}

function getLangJsonTranslation(params: GetLangJsonTranslationParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.getLangJsonTranslation,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  });
}

function getLanguagesToManage(): Promise<Lang[]> {
  return axios.request<Lang[]>({
    url: paths.getLanguagesToManage,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface SetPublishedParams {
  langId?: number;
  published?: boolean;
}

function setPublished(params: SetPublishedParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.setPublished,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

interface MarkKeyAsRemovedParams {
  keyId?: number;
}

function markKeyAsRemoved(params: MarkKeyAsRemovedParams): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.markKeyAsRemoved,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  });
}

function prepareKeysFromFile(data: KeyTranslationImportRequest): Promise<KeyTranslationImport> {
  return axios.request<KeyTranslationImport>({
    url: paths.prepareKeysFromFile,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

interface GetKeysTranslationsParams {
  langId?: number;
}

function getKeysTranslations(params: GetKeysTranslationsParams): Promise<KeyTranslation[]> {
  return axios.request<KeyTranslation[]>({
    url: paths.getKeysTranslations,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function updateTranslations(data: KeyTranslationUpdate[]): Promise<KeysUpdateStats> {
  return axios.request<KeysUpdateStats>({
    url: paths.updateTranslations,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

interface GeneratePoFileParams {
  langId?: number;
  sortBySources?: boolean;
}

function generatePoFile(params: GeneratePoFileParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.generatePoFile,
    method: 'get',
    params,
    responseType: 'blob',
    headers: staticRequestHeaders,
  }).then(convertBlobResponseToFile);
}

function generatePotFileFromServerStrings(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.generatePotFileFromServerStrings,
    method: 'get',
    headers: staticRequestHeaders,
  });
}

function generatePotFileFromServerStringsMergedWithProvidedFile(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.generatePotFileFromServerStringsMergedWithProvidedFile,
    method: 'post',
    headers: staticRequestHeaders,
  });
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const intlService = {
  paths,
  getLanguages,
  getLangJsonTranslation,
  getLanguagesToManage,
  setPublished,
  markKeyAsRemoved,
  prepareKeysFromFile,
  getKeysTranslations,
  updateTranslations,
  generatePoFile,
  generatePotFileFromServerStrings,
  generatePotFileFromServerStringsMergedWithProvidedFile,
};
