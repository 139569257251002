import { systemState } from "../global/systemState";
import desktopManager from "../desktop/desktopManager";
import { authService, clientConfigService } from "apinet";
import { wsHub } from "core/ws";

const securityService = {
  updateLoggedUser,
  updateAppConfig,
  login,
  loginWithPassChange,
  logout,
};

// eslint-disable-next-line import/no-default-export
export default securityService;

function updateLoggedUser() {
  return new Promise<void>((resolve, reject) => {
    authService
      .info()
      .then(authInfo => {
        systemState.setAuthInfo(authInfo);
        updateAppConfig();
      })
      .catch(err => {
        systemState.setAuthInfo(undefined);
        wsHub.stop();
      })
      .finally(() => {
        resolve();
      });
  });
}

function updateAppConfig() {
  return new Promise<void>((resolve, reject) => {
    clientConfigService
      .getClientExposedConfig()
      .then(cfg => {
        systemState.setConfig(cfg);
        wsHub.start();
      })
      .catch(err => {
        systemState.setConfig(undefined);
      })
      .finally(() => {
        resolve();
      });
  });
}

function login(login: string, password: string) {
  return authService.login({ login, password }).then(res => {
    if (res.success) {
      updateLoggedUser();
    }

    return res;
  });
}

function loginWithPassChange(login: string, password: string, newPassword: string, newPassword2: string) {
  return authService.login_with_pass_change({ login, password, newPassword, newPassword2 }).then(res => {
    if (res.success) {
      updateLoggedUser();
    }

    return res;
  });
}

function logout(reason?: string) {
  desktopManager.closeAllWindows();
  
  const isActiveImpersonatingUser = Boolean(systemState.state.impersonatingUser);
  return authService.logout().then(() => {
    if (isActiveImpersonatingUser) {
      window.location.reload();
    } else {
      return updateLoggedUser();
    }
  });
}
