import React from "react";
import { FormContext } from "./FormContext";
import { Omit } from "../../utils/typescript";
import { Button } from "../Button";

interface SubmitButtonProps extends Omit<React.ComponentProps<typeof Button>, "onClick"> {}

export class SubmitButton extends React.PureComponent<SubmitButtonProps> {
  static contextType = FormContext;
  declare context: React.ContextType<typeof FormContext>;

  render() {
    const props = this.props;
    const { handleSubmit, disabled } = this.context;
    const { children, disabled: propsDisabled, ...attrs } = this.props;

    return (
      <Button buttonType="submit" disabled={disabled || propsDisabled} onClick={handleSubmit} {...attrs}>
        {props.children}
      </Button>
    );
  }
}
