import { useCallback, useMemo, useState } from "react";
import { ToastList, ToastListContext, ToastManager, ToastManagerContext } from "./ToastManagerContext";
import { ToastInstance, ToastModel } from "./ToastModel";

interface ToastContextProviderProps {
  children: React.ReactNode;
}

let toastIdGen = 1;

export function ToastContextProvider(props: ToastContextProviderProps) {
  const [toasts, setToasts] = useState<ToastInstance[]>([]);

  const toastManager = useMemo<ToastManager>(() => {
    return {
      add(model: ToastModel) {
        setToasts(prev => prev.concat({ id: toastIdGen++, model }));
      },
      close(id: number) {
        setToasts(prev => prev.filter(x => x.id !== id));
      },
      closeAll() {
        setToasts([]);
      },
    };
  }, []);

  const toastsList = useMemo<ToastList>(
    () => ({
      toasts,
    }),
    [toasts]
  );

  return (
    <ToastListContext.Provider value={toastsList}>
      <ToastManagerContext.Provider value={toastManager}>{props.children}</ToastManagerContext.Provider>
    </ToastListContext.Provider>
  );
}
