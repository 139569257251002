// File generated automatically, do not modify

import axios from 'axios';
import { ClientExposedConfig } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getClientExposedConfig: 'api/ClientConfig/GetClientExposedConfig',
  getLogoSvg: 'api/ClientConfig/GetLogoSvg',
};

function getClientExposedConfig(): Promise<ClientExposedConfig> {
  return axios.request<ClientExposedConfig>({
    url: paths.getClientExposedConfig,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface GetLogoSvgParams {
  encoded?: boolean;
  buttonVariant?: boolean;
}

function getLogoSvg(params: GetLogoSvgParams): Promise<string> {
  return axios.request<string>({
    url: paths.getLogoSvg,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

export const clientConfigService = {
  paths,
  getClientExposedConfig,
  getLogoSvg,
};
