import { maintenanceService } from "apinet";
import { AppCondition, AppConditionMessage } from "apinet/models";
import { ChildrenProps } from "core";
import { useTr } from "core/intl";
import { PendingOperationState, useIntervalDataLoader, wait } from "core/utils";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";

interface AppConditionReportContextData {
  appCondition: AppCondition | undefined;
  op: PendingOperationState;
}

type ForceRefreshFn = () => Promise<AppCondition | undefined>;

const AppConditionReportContext = createContext<AppConditionReportContextData>({
  appCondition: undefined,
  op: {
    error: false,
    pending: false,
    success: false,
    operationCounter: 0,
    errorMessage: "",
    technicalError: "",
  },
});

const AppConditionReportRefreshContext = createContext<ForceRefreshFn>(() => Promise.reject());

export function AppConditionContextProvider(props: ChildrenProps) {
  const forceRefreshRef = useRef(false);
  const isFirstCallRef = useRef(true);
  const [data, loading, refresh] = useIntervalDataLoader(async () => {
    if (isFirstCallRef.current) {
      isFirstCallRef.current = false;
      const delayMs = Math.ceil(Math.random() * 2_000) + 1000;
      await wait(delayMs);
    }

    const requestPromise = maintenanceService.getAppCondition({ forceRefresh: forceRefreshRef.current || undefined });
    forceRefreshRef.current = false;
    return requestPromise;
  }, 300_000);

  const reportContextValue = useMemo<AppConditionReportContextData>(
    () => ({ appCondition: data, op: loading.state }),
    [data, loading.state]
  );

  const forceRefresh = useCallback(() => {
    forceRefreshRef.current = true;
    return refresh();
  }, [refresh]);

  return (
    <AppConditionReportContext.Provider value={reportContextValue}>
      <AppConditionReportRefreshContext.Provider value={forceRefresh}>
        {props.children}
      </AppConditionReportRefreshContext.Provider>
    </AppConditionReportContext.Provider>
  );
}

export function useAppConditionReport(): AppConditionReportContextData {
  return useContext(AppConditionReportContext);
}

export function useAppConditionReportForceRefresh(): ForceRefreshFn {
  return useContext(AppConditionReportRefreshContext);
}

export function useAppConditionGroupedByCategory(): Record<string, AppConditionMessage[]> | undefined {
  const ctx = useAppConditionReport();
  const tr = useTr();
  const appCondition = ctx.appCondition;

  return useMemo(() => {
    if (!appCondition || !appCondition.messages.length) {
      return undefined;
    }

    const result: Record<string, AppConditionMessage[]> = {};

    for (let msg of appCondition.messages) {
      let category = msg.category || tr("Ogólny problem");
      if (msg.superUserOnly) {
        category = `[SU] ${category}`;
      }
      if (!result[category]) {
        result[category] = [msg];
      } else {
        result[category].push(msg);
      }
    }

    return result;
  }, [appCondition, tr]);
}
