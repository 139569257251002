import React, { useContext } from "react";
import { TextArea } from "../controls/TextArea";
import { FormContext } from "./FormContext";

type TextAreaProps = React.ComponentProps<typeof TextArea>;

interface TextAreaFieldProps extends Omit<TextAreaProps, "value" | "onChange" | "onEnter"> {
  name: string;
  inputName?: string;
}

export const TextAreaField = React.memo(function TextAreaField(props: TextAreaFieldProps) {
  const form = useContext(FormContext);
  let { name, inputName, ...restAttrs } = props;
  const value = form.model[name] || "";

  return (
    <TextArea
      {...restAttrs}
      onChange={value => form.handleFieldChange(name, value)}
      value={value}
      name={inputName}
      disabled={props.hasOwnProperty("disabled") ? props.disabled : form.disabled}
    />
  );
});
