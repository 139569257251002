import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(HttpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init<HttpBackendOptions & DetectorOptions>({
    debug: false,
    // debug: import.meta.env.DEV",
    fallbackLng: false,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "api/Intl/GetLangJsonTranslation?langCode={{lng}}&ns={{ns}}",
      customHeaders: {
        "x-api-v2": "1",
      },
    },
    detection: {
      order: ["querystring", "cookie", "navigator"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "lng",
      lookupCookie: "lng",
      caches: ["cookie"]
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
