import { FeedbackMessage } from "core/content/FeedbackMessage";
import { SimpleContainer } from "core/layout/SimpleContainer";
import { LoadingSpinner } from "./LoadingSpinner";
import { Text } from "core/content/Text";

export function StandardLazyComponentFallback() {
  return (
    <SimpleContainer padding>
      <FeedbackMessage blockCenter>
        <Text>Wczytywanie...</Text>
        <LoadingSpinner />
      </FeedbackMessage>
    </SimpleContainer>
  );
}
