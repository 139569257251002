import React, { cloneElement, useCallback, useContext, useMemo, useState } from "react";

interface HoverRegionProps {
  onHoverChange?: (isHover: boolean) => any;
  children?: React.ReactNode;
}

interface IHoverRegionContext {
  isHover: boolean;
}

HoverRegion.Context = React.createContext<IHoverRegionContext>({
  isHover: false,
});

HoverRegion.WhenHover = WhenHover;

export function HoverRegion(props: HoverRegionProps) {
  const { children, onHoverChange } = props;
  const [isHover, setIsHover] = useState(false);
  const ctxValue = useMemo<IHoverRegionContext>(() => ({ isHover }), [isHover]);

  const onMouseOver = useCallback(() => {
    setIsHover(true);
    onHoverChange && onHoverChange(true);
  }, [onHoverChange]);

  const onMouseLeave = () => {
    setIsHover(false);
    onHoverChange && onHoverChange(false);
  };

  const child = getChildAsElement(children);
  if (!child) return null;

  const hoverProps: React.HTMLAttributes<HTMLElement> = {
    onMouseOver,
    onMouseLeave,
  };

  return (
    <HoverRegion.Context.Provider value={ctxValue}>{cloneElement(child, hoverProps)}</HoverRegion.Context.Provider>
  );
}

function getChildAsElement(children: React.ReactNode): React.ReactElement | undefined | null {
  if (children === null || children === undefined) return children as any;

  return typeof children === "object" ? (React.Children.only(children) as React.ReactElement) : <span>{children}</span>;
}

function WhenHover(props: {children?: React.ReactNode}) {
  const ctx = useContext(HoverRegion.Context);
  if (!ctx.isHover) return null;

  return (props.children === undefined ? null : props.children) as any;
}