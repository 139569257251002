// File generated automatically, do not modify

import axios from 'axios';
import { ContextHelpMetadata, ContextHelpArticle } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getMetadataForCode: 'api/ContextHelp/GetMetadataForCode',
  getArticleById: 'api/ContextHelp/GetArticleById',
};

interface GetMetadataForCodeParams {
  code: string;
}

function getMetadataForCode(params: GetMetadataForCodeParams): Promise<ContextHelpMetadata> {
  return axios.request<ContextHelpMetadata>({
    url: paths.getMetadataForCode,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

interface GetArticleByIdParams {
  id?: number;
}

function getArticleById(params: GetArticleByIdParams): Promise<ContextHelpArticle> {
  return axios.request<ContextHelpArticle>({
    url: paths.getArticleById,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

export const contextHelpService = {
  paths,
  getMetadataForCode,
  getArticleById,
};
