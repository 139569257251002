// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { FileMetadata, FilesCriteria, FileListResponse, FileUploadParams, FileUpdateParams } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  byFileId: 'api/Files/{fileId}',
  getMetadataById: 'api/Files/GetMetadataById',
  getFileList: 'api/Files/GetFileList',
  getCategories: 'api/Files/GetCategories',
  upload: 'api/Files/Upload',
  update: 'api/Files/Update',
  remove: 'api/Files/Remove',
};

function byFileId(fileId: number): Promise<File> {
  return axios.request<Blob>({
    url: applyPathParameters(paths.byFileId, { fileId }),
    method: 'get',
    responseType: 'blob',
    headers: staticRequestHeaders,
  }).then(convertBlobResponseToFile);
}

interface GetMetadataByIdParams {
  fileId: number;
}

function getMetadataById(params: GetMetadataByIdParams): Promise<FileMetadata> {
  return axios.request<FileMetadata>({
    url: paths.getMetadataById,
    method: 'get',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function getFileList(data: FilesCriteria): Promise<FileListResponse> {
  return axios.request<FileListResponse>({
    url: paths.getFileList,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function getCategories(): Promise<string[]> {
  return axios.request<string[]>({
    url: paths.getCategories,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function upload(data: FileUploadParams): Promise<FileMetadata> {
  return axios.request<FileMetadata>({
    url: paths.upload,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function update(data: FileUpdateParams): Promise<FileMetadata> {
  return axios.request<FileMetadata>({
    url: paths.update,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

interface RemoveParams {
  fileId: number;
}

function remove(params: RemoveParams): Promise<FileMetadata> {
  return axios.request<FileMetadata>({
    url: paths.remove,
    method: 'post',
    params,
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function applyPathParameters(path: string, parameters: any) {
  for (let par in parameters) {
    path = path.replace('{' + par + '}', parameters[par]);
  }
  return path;
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const filesService = {
  paths,
  byFileId,
  getMetadataById,
  getFileList,
  getCategories,
  upload,
  update,
  remove,
};
