import React from "react";
import { currentUnixTime, utcSecondsToDateTime } from "./dateUtils";
import { Text } from "core/content";

interface LastUpdateTimeProps {
  unixTime: number;
  delayToleranceSeconds?: number;
}

interface LastUpdateTimeState {
  aboveThreshold: boolean;
}

export class LastUpdateTime extends React.Component<LastUpdateTimeProps, LastUpdateTimeState> {
  timer?: number;

  constructor(props: LastUpdateTimeProps) {
    super(props);

    this.state = {
      aboveThreshold: false,
    };
  }

  render() {
    return (
      <Text danger={this.state.aboveThreshold}>
        {this.props.unixTime ? utcSecondsToDateTime(this.props.unixTime) : "-"}
      </Text>
    );
  }

  componentDidMount() {
    this.timer = window.setInterval(() => this.updateIsAboveThreshold(), 1000);
    this.updateIsAboveThreshold();
  }

  componentWillUnmount() {
    if (this.timer) window.clearInterval(this.timer);
  }

  updateIsAboveThreshold() {
    const maxDelayTolerance = this.props.delayToleranceSeconds || 120;
    const current = currentUnixTime();
    const delay = current - this.props.unixTime;
    const isAbove = delay > maxDelayTolerance;
    if (isAbove !== this.state.aboveThreshold) {
      this.setState({
        aboveThreshold: isAbove,
      });
    }
  }
}
