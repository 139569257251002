import React from "react";
import { TextField } from ".";

interface PasswordFieldProps extends React.ComponentProps<typeof TextField> {}

export class PasswordField extends React.PureComponent<PasswordFieldProps> {
  render() {
    return <TextField {...this.props} type="password" />;
  }
}
