import { defineWindowDetails, useInitWindowSize } from "core/desktop";
import { WindowLazyContent } from "core/desktop/WindowLazyContent";
import { tx } from "core/intl";
import { lazy } from "react";

type ContextHelpWindowProps = React.ComponentProps<typeof ContextHelpWindowContent>;

const ContextHelpWindowContent = lazy(() =>
  import("./ContextHelpWindowContent").then(m => ({ default: m.ContextHelpWindowContent }))
);

defineWindowDetails(ContextHelpWindow, {
  icon: "far fa-question",
  title: tx("Pomoc kontekstowa"),
});

export function ContextHelpWindow(props: ContextHelpWindowProps) {
  useInitWindowSize(600, 400);

  return (
    <WindowLazyContent>
      <ContextHelpWindowContent {...props} />
    </WindowLazyContent>
  );
}
