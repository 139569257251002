import { GlobalState } from "react-gstate";
import { userAgentUtils } from "core/utils/userAgentUtils";
import { startMenuState } from "./startMenuState";

export interface PhoneViewState {
  isMobileBrowser: boolean;
  phoneViewEanbled: boolean;
  windowListPanelVisible: boolean;
  windowHeight: number;
  windowWidth: number;
}

class PhoneViewStateStore extends GlobalState<PhoneViewState> {
  togglePhoneView() {
    this.setState({
      phoneViewEanbled: !this.state.phoneViewEanbled
    });
  }

  toggleWindowListPanel() {
    startMenuState.hide();
    this.setState({
      windowListPanelVisible: !this.state.windowListPanelVisible
    });
  }

  hideWindowListPanel() {
    this.setState({
      windowListPanelVisible: false
    });
  }
}

const isMobileBrowser = userAgentUtils.isMobileBrowser();

export const phoneViewState = new PhoneViewStateStore({
  isMobileBrowser,
  phoneViewEanbled: isMobileBrowser,
  windowListPanelVisible: false,
  windowHeight: 0,
  windowWidth: 0,
});

export function usePhoneViewEnabled() {
  return phoneViewState.useGlobalState((ss) => ss.phoneViewEanbled);
}
