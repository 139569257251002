import { GlobalPriv } from "apinet/models";

export type GlobalPrivCode = keyof typeof GlobalPriv;
export type ObjectPrivCode = "objectAccess";
export type PrivsMap = Record<GlobalPrivCode, boolean | undefined>;

export function getDefaultPrivsMap(): PrivsMap {
  const result: any = {};

  for (let key in GlobalPriv) {
    if (typeof key === "string" && GlobalPriv.hasOwnProperty(key)) {
      result[key] = undefined;
    }
  }

  return result;
}
