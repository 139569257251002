const dragHelper = {
  startDragMove,
  startDragResize,
  onMouseDownPotentialResize,
  updateCursorStyleForDragResize: updateCursorStyle
};

export type ResizeDirection = "nw" | "n" | "ne" | "e" | "se" | "s" | "sw" | "w";

// eslint-disable-next-line import/no-default-export
export default dragHelper;

function startDragMove(elementToMove: HTMLElement, event: MouseEvent, onDragEnd?: () => void, onDragMove?: (event: MouseEvent) => void) {
  const windowOriginalLeft = parseInt(elementToMove.style.left || "");
  const windowOriginalTop = parseInt(elementToMove.style.top || "");
  const diffLeft = event.clientX - windowOriginalLeft;
  const diffTop = event.clientY - windowOriginalTop;

  const onMousemove = (event: MouseEvent) => {
    if (onDragMove) {
      onDragMove(event);
    }

    let newX = Math.floor(event.clientX - diffLeft);
    let newY = Math.floor(event.clientY - diffTop);

    elementToMove.style.top = newY + "px";
    elementToMove.style.left = newX + "px";
  };

  const onMouseup = (event: MouseEvent) => {
    document.removeEventListener("mousemove", onMousemove);
    document.removeEventListener("mouseup", onMouseup);

    if (onDragEnd) {
      onDragEnd();
    }
  };

  document.addEventListener("mousemove", onMousemove);
  document.addEventListener("mouseup", onMouseup);
}

function startDragResize(
  elementToResize: HTMLElement,
  event: MouseEvent,
  direction: ResizeDirection,
  resizeEndCallback?: () => void
) {
  const windowOriginalLeft = parseInt(elementToResize.style.left || "");
  const windowOriginalTop = parseInt(elementToResize.style.top || "");
  const windowOriginalWidth = parseInt(elementToResize.style.width || "");
  const windowOriginalHeight = parseInt(elementToResize.style.height || "");
  const diffLeft = event.clientX - windowOriginalLeft;
  const diffTop = event.clientY - windowOriginalTop;
  const diffWidth = event.clientX - windowOriginalWidth;
  const diffHeight = event.clientY - windowOriginalHeight;
  const startX = event.clientX;
  const startY = event.clientY;

  const onMousemove = (event: MouseEvent) => {
    switch (direction) {
      case "n":
        elementToResize.style.top = event.clientY - diffTop + "px";
        elementToResize.style.height = (windowOriginalHeight - (event.clientY - startY)) + "px";
        break;
      case "e":
        elementToResize.style.width = event.clientX - diffWidth + "px";
        break;
      case "s":
        elementToResize.style.height = event.clientY - diffHeight + "px";
        break;
      case "w":
        elementToResize.style.left = event.clientX - diffLeft + "px";
        elementToResize.style.width = (windowOriginalWidth - (event.clientX - startX)) + "px";
        break;
      case "ne":
        elementToResize.style.top = event.clientY - diffTop + "px";
        elementToResize.style.height = (windowOriginalHeight - (event.clientY - startY)) + "px";
        elementToResize.style.width = event.clientX - diffWidth + "px";
        break;
      case "nw":
        elementToResize.style.top = event.clientY - diffTop + "px";
        elementToResize.style.height = (windowOriginalHeight - (event.clientY - startY)) + "px";
        elementToResize.style.left = event.clientX - diffLeft + "px";
        elementToResize.style.width = (windowOriginalWidth - (event.clientX - startX)) + "px";
        break;
      case "se":
        elementToResize.style.height = event.clientY - diffHeight + "px";
        elementToResize.style.width = event.clientX - diffWidth + "px";
        break;
      case "sw":
        elementToResize.style.height = event.clientY - diffHeight + "px";
        elementToResize.style.left = event.clientX - diffLeft + "px";
        elementToResize.style.width = (windowOriginalWidth - (event.clientX - startX)) + "px";
        break;
    }
  };

  const onMouseup = (event: MouseEvent) => {
    document.removeEventListener("mousemove", onMousemove);
    document.removeEventListener("mouseup", onMouseup);

    if (resizeEndCallback) {
      resizeEndCallback();
    }
  };

  document.addEventListener("mousemove", onMousemove);
  document.addEventListener("mouseup", onMouseup);
}

function getResizeDirection(el: HTMLElement, event: MouseEvent): ResizeDirection | undefined {
  const rect = el.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  const delta = 15;
  const leftEdge = x < delta;
  const rightEdge = x > (rect.width - delta);
  const topEdge = y < delta;
  const bottomEdge = y > (rect.height - delta);

  if (leftEdge) {
    if (topEdge)
      return 'nw';
    else if (bottomEdge)
      return 'sw';
    else
      return 'w';
  }
  else if (rightEdge) {
    if (topEdge)
      return 'ne';
    else if (bottomEdge)
      return 'se';
    else
      return 'e';
  }
  else if (topEdge) {
    return 'n';
  }
  else if (bottomEdge) {
    return 's';
  }
  else {
    return undefined;
  }
}

function onMouseDownPotentialResize(el: HTMLElement, event: MouseEvent, onResizeStart?: () => any, onResizeEnd?: () => any) {
  if (event.target !== el) return;
  if (!el.style.width || el.style.width.indexOf('px') < 0) return;
  startResizing(el, event, onResizeStart, onResizeEnd);
}

function startResizing(el: HTMLElement, event: MouseEvent, onResizeStart?: () => void, onResizeEnd?: () => void) {
  const direction = getResizeDirection(el, event);
  if (direction) {
    if (onResizeStart) {
      onResizeStart();
    }
    dragHelper.startDragResize(el, event, direction, onResizeEnd);
  }
}

function updateCursorStyle(el: HTMLElement, event: MouseEvent) {
  const resizeDirection = getResizeDirection(el, event);
  if (resizeDirection) {
    el.style.setProperty('cursor', resizeDirection + '-resize');
  }
}