import React, { SetStateAction, useContext } from "react";
import { Form } from "./Form";
import { FormContext, IFormContext } from "./FormContext";

type NestedChildrenRenderFn = (item: any) => React.ReactNode;

interface FormNestingProps {
  children: React.ReactNode | NestedChildrenRenderFn;
  object?: string;
  array?: string;
  itemKeyField?: string;
  rootComponent?: React.ComponentType | string;
}

export const FormNesting = React.memo(function FormNesting(props: FormNestingProps) {
  const fieldName = props.object || props.array || "";
  if (!fieldName) throw new Error("Missing object or array nested field name param");

  const parentForm = useContext(FormContext);

  if (props.object) {
    const value = (parentForm.model[fieldName] || {}) as { [key: string]: any };

    return (
      <Form
        model={value}
        disabled={parentForm.disabled}
        onChangeDispatch={setStateAction => handleObjectChange(setStateAction, fieldName, parentForm)}
        onSubmit={() => parentForm.handleSubmit()}
        parentFieldName={getCombinedParentFormName(parentForm, fieldName)}
        rootComponent={props.rootComponent}
        nestedFormField={fieldName}
      >
        {typeof props.children === "function" ? props.children(value) : props.children}
      </Form>
    );
  } else if (props.array) {
    const value = (parentForm.model[fieldName] || []) as any[];

    const items = value.map((item, index) => (
      <Form
        key={props.itemKeyField ? item[props.itemKeyField] : index}
        model={item}
        disabled={parentForm.disabled}
        onChangeDispatch={(setStateAction: SetStateAction<any>) => {
          handleArrayFieldChange(
            setStateAction,
            index,
            props.itemKeyField,
            props.itemKeyField ? item[props.itemKeyField] : undefined,
            fieldName,
            parentForm
          );
        }}
        onSubmit={() => parentForm.handleSubmit()}
        parentFieldName={getCombinedParentFormName(parentForm, `${fieldName}[${index}]`)}
        rootComponent={props.rootComponent}
        nestedFormField={fieldName}
        nestedFormFieldIndex={index}
      >
        {typeof props.children === "function" ? props.children(item) : props.children}
      </Form>
    ));
    return <>{items}</>;
  }

  return null;
});

function handleObjectChange(setStateAction: SetStateAction<any>, fieldName: string, parentForm: IFormContext<any>) {
  parentForm.handleModelChange((parentModel: any) => {
    const prevObj = parentModel[fieldName];
    const newObj = unsafeIsSetStateActionFunction(setStateAction) ? setStateAction(prevObj) : setStateAction;
    return {
      ...parentModel,
      [fieldName]: newObj,
    };
  });
}

function handleArrayFieldChange(
  setStateAction: SetStateAction<any>,
  index: number,
  itemKeyField: string | undefined,
  itemKey: any,
  fieldName: string,
  parentForm: IFormContext<any>
) {
  parentForm.handleModelChange((parentModel: any) => {
    const arr = (parentModel[fieldName] || []) as any[];
    const newArr = arr.slice();
    const prevItemData = itemKeyField ? arr.find(x => x[itemKeyField] === itemKey) : arr[index];
    const prevItemIndex = arr.indexOf(prevItemData);
    const newItemData = unsafeIsSetStateActionFunction(setStateAction) ? setStateAction(prevItemData) : setStateAction;
    newArr.splice(prevItemIndex, 1, newItemData);

    return {
      ...parentModel,
      [fieldName]: newArr,
    };
  });
}

function getCombinedParentFormName(parentForm: IFormContext, field: string): string {
  return [parentForm.parentFieldName, field].filter(Boolean).join(".");
}

function unsafeIsSetStateActionFunction(arg: any): arg is (prevState: any) => any {
  return typeof arg === "function";
}
