import { GlobalState } from "react-gstate";
import {
  AuthInfo,
  AuthInfoUser,
  ClientExposedConfig,
  ClientExposedConfigBusDelayConfig,
  IdLabel,
  UserType,
} from "apinet/models";
import { getDefaultPrivsMap, PrivsMap } from "common/userTypes";

export interface SystemState {
  user: AuthInfoUser | undefined;
  impersonatingUser?: IdLabel;
  lastLogoutReason?: string;
  isSuperUser: boolean;
  isPresentationModeActive: boolean;
  privs: PrivsMap;
  config: ClientExposedConfig | undefined;
  nativeWindowMode: boolean;
  wsHubConnectionProblem: boolean;
  wsBlockedDetected: boolean;
}

const presentationModeLocalStorageKey = "presentationMode";

class SystemStateStore extends GlobalState<SystemState> {
  setAuthInfo(info: AuthInfo | undefined) {
    if (info) {
      const privs = getDefaultPrivsMap();
      const isSuperUser = info.user.type === UserType.super;
      info.privs.forEach(x => ((privs as any)[x] = true));

      this.setState({
        user: info.user,
        impersonatingUser: info.impersonatingUser,
        privs,
        isSuperUser,
        isPresentationModeActive:
          isPresentationModeAvailable(isSuperUser, privs) && isPresentationModeInLocalStorageActive(),
      });
    } else {
      this.setState({
        user: undefined,
        impersonatingUser: undefined,
        privs: {} as any,
        isSuperUser: false,
        isPresentationModeActive: false,
      });
    }
  }

  setConfig(cfg: ClientExposedConfig | undefined) {
    this.setState({
      config: cfg,
    });

    if (cfg) {
      window.document.title = cfg.brandingEnabled ? cfg.brandName || "ZIR" : "ZIR24";
    }
  }

  setNativeWindowMode(enabled: boolean) {
    this.setState({
      nativeWindowMode: enabled,
    });
  }

  setWsHubConnectionProblem(connectionProblem: boolean) {
    if (connectionProblem === this.state.wsHubConnectionProblem) return;

    this.setState({
      wsHubConnectionProblem: connectionProblem,
    });
  }

  setWsBlockedDetected(wsBlockedDetected: boolean) {
    if (wsBlockedDetected === this.state.wsBlockedDetected) return;

    this.setState({
      wsBlockedDetected,
    });
  }

  setLastLogoutReason(reason?: string) {
    this.setState({
      lastLogoutReason: reason,
    });
  }

  setPresentationModeActive(active: boolean) {
    if (active) {
      if (!isPresentationModeAvailable(this.state.isSuperUser, this.state.privs)) {
        return;
      }

      setPresentationModeInLocalStorage(true);
      this.setState({
        isPresentationModeActive: true,
      });
    } else {
      setPresentationModeInLocalStorage(false);
      this.setState({
        isPresentationModeActive: false,
      });
    }
  }

  connectPrivs(cmp: React.Component<any, { privs: PrivsMap }>) {
    return this.connect(cmp, connectPrivsFn);
  }

  connectIsSu(cmp: React.Component<any, { isSuperUser: boolean }>) {
    return this.connect(cmp, connectIsSuFn);
  }

  connectConfig(cmp: React.Component<any, { appCfg: ClientExposedConfig | undefined }>) {
    return this.connect(cmp, connectCfgFn);
  }

  connectConfig2(cmp: React.Component<any, { appCfg: ClientExposedConfig | undefined }>) {
    return this.connect(cmp, connectCfgFn);
  }

  connectBusModuleEnabled(cmp: React.Component<any, { busModuleEnabled: boolean }>) {
    return this.connect(cmp, connectBusModuleEnabledFn);
  }
}

function connectPrivsFn(gs: SystemState) {
  return { privs: gs.privs };
}

function connectIsSuFn(gs: SystemState) {
  return { isSuperUser: gs.isSuperUser };
}

function connectCfgFn(gs: SystemState) {
  return { appCfg: gs.config };
}

function connectBusModuleEnabledFn(gs: SystemState) {
  return {
    busModuleEnabled: (gs.config && gs.config.isBusModuleEnabled) || false,
  };
}

export function isPresentationModeAvailable(isSu: boolean, privs: PrivsMap): boolean {
  return Boolean(!isSu && privs.browserPresentationMode);
}

function isPresentationModeInLocalStorageActive(): boolean {
  return Boolean(window.localStorage.getItem(presentationModeLocalStorageKey));
}

function setPresentationModeInLocalStorage(active: boolean) {
  if (active) {
    window.localStorage.setItem(presentationModeLocalStorageKey, "1");
  } else {
    window.localStorage.removeItem(presentationModeLocalStorageKey);
  }
}

export const systemState = new SystemStateStore({
  isSuperUser: false,
  privs: {} as any,
  config: undefined,
  user: undefined,
  nativeWindowMode: false,
  wsHubConnectionProblem: false,
  wsBlockedDetected: false,
  isPresentationModeActive: false,
});

export function useUserPrivs() {
  return systemState.useGlobalState(ss => ss.privs);
}

export function useIsSu() {
  return systemState.useGlobalState(ss => ss.isSuperUser);
}

export function useUserSingleTenantId(): number | undefined {
  return systemState.useGlobalState(ss => ss.user?.singleTenantId);
}

export function usePresentationModeActive(): boolean {
  return systemState.useGlobalState(ss => ss.isPresentationModeActive);
}

export function usePresentationModeAvailable(): boolean {
  return systemState.useGlobalState(ss => isPresentationModeAvailable(ss.isSuperUser, ss.privs));
}

export function useBusModuleEnabled() {
  return systemState.useGlobalState(ss => ss.config && ss.config.isBusModuleEnabled);
}

const defaultBusDelayConfig: ClientExposedConfigBusDelayConfig = {
  lowPriorityMinDelay: 1,
  mediumPriorityMinDelay: 5,
  highPriorityMinDelay: 10,
};

export function getBusDelayConfig(): ClientExposedConfigBusDelayConfig {
  return systemState.state.config?.busDelayConfig || defaultBusDelayConfig;
}

export function useBusDelayConfig(): ClientExposedConfigBusDelayConfig {
  const loadedBusDelayConfig = systemState.useGlobalState(gs => gs.config?.busDelayConfig);
  return loadedBusDelayConfig || defaultBusDelayConfig;
}
