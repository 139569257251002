import { tx, useTr } from "core/intl";
import { Icon, IconSymbol } from "core/utils";

interface WindowLockModeButtonProps {
  value: WindowLockMode;
  onChange: (newValue: WindowLockMode) => any;
}

export enum WindowLockMode {
  noLock = 0,
  lockAsBackground = 1,
  lockForeground = 2,
}

const lockModeText: Record<WindowLockMode, string> = {
  [WindowLockMode.noLock]: tx("Brak"),
  [WindowLockMode.lockAsBackground]: tx("Zawsze w tle"),
  [WindowLockMode.lockForeground]: tx("Zawsze na wierzchu"),
};

const lockModeIcon: Record<WindowLockMode, IconSymbol> = {
  [WindowLockMode.noLock]: "minus",
  [WindowLockMode.lockAsBackground]: "arrowDown",
  [WindowLockMode.lockForeground]: "arrowUp",
};

const nextLockModeChain: Record<WindowLockMode, WindowLockMode> = {
  [WindowLockMode.noLock]: WindowLockMode.lockForeground,
  [WindowLockMode.lockForeground]: WindowLockMode.lockAsBackground,
  [WindowLockMode.lockAsBackground]: WindowLockMode.noLock,
};

export function WindowLockModeButton(props: WindowLockModeButtonProps) {
  const { value, onChange } = props;
  const tr = useTr();
  const isLocked = value !== WindowLockMode.noLock;

  const switchLockMode = () => {
    const nextLockMode = nextLockModeChain[value] || WindowLockMode.noLock;
    onChange(nextLockMode);
  };

  return (
    <button onClick={switchLockMode} title={tr("Przełącz tryb blokady okna na wierzchu/jako tło")}>
      <Icon symbol={isLocked ? "lock" : "lockOpen"} />
      {value ? <Icon title={tr(lockModeText[value])} symbol={lockModeIcon[value]} /> : null}
    </button>
  );
}
