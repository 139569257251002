import { Text } from "core/content";
import { ButtonGroup } from "core/layout";
import React, { useState } from "react";
import { authService } from "../../apinet";
import { Button, Form, FormItem, TextInput } from "../forms";
import { PendingOperationStatus, usePendingOperation } from "../utils";

interface PasswordRecoveryFormProps {
  login?: string;
  onCompleted: (login: string) => any;
  onCancel: () => any;
}

export function PasswordRecoveryForm(props: PasswordRecoveryFormProps) {
  const { onCompleted, onCancel } = props;
  const [login, setLogin] = useState(props.login || "");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [completePhase, setCompletePhase] = useState(false);
  const [tokenSent, setTokenSent] = useState(false);
  const [finished, setFinished] = useState(false);
  const op = usePendingOperation();
  const sendTokenDisabled = op.state.pending || !login || !email;
  const sendCompleteDisabled =
    op.state.pending || !login || !email || !token || !newPassword || !newPassword2 || newPassword !== newPassword2;

  const sendToken = () => {
    if (op.syncPending) return;
    if (sendTokenDisabled) return;

    op.handle(
      authService
        .startPasswordRecovery({
          login,
          email,
        })
        .then(() => {
          setTokenSent(true);
          setCompletePhase(true);
        })
    );
  };

  const sendComplete = () => {
    if (op.syncPending) return;
    if (sendCompleteDisabled) return;

    op.handle(
      authService
        .completePasswordRecovery({
          login,
          email,
          token,
          newPassword,
          newPassword2,
        })
        .then(() => {
          setTokenSent(false);
          setFinished(true);
          window.setTimeout(() => onCompleted(login), 2000);
        })
    );
  };

  const submit = () => {
    if (completePhase) {
      sendComplete();
    } else {
      sendToken();
    }
  };

  if (finished) {
    return (
      <div className="recovery-form">
        <div className="text-center">
          <h4 className="success-hint">Pomyślnie zaktualizowano hasło</h4>
        </div>
      </div>
    );
  }

  return (
    <div className="recovery-form">
      <Form disabled={op.state.pending || completePhase}>
        <div className="text-center">
          <h6 className="recovery-hint">
            Podaj dane użytkownika, by odzyskać hasło
            <br />
            za pomocą kodu autoryzacyjnego
          </h6>
        </div>
        <FormItem label="Użytkownik">
          <TextInput
            value={login}
            onChange={setLogin}
            autoFocus={!props.login}
            placeholder="Użytkownik"
            disabled={op.state.pending || completePhase}
            onEnter={submit}
          />
        </FormItem>
        <FormItem label="Email">
          <TextInput
            value={email}
            onChange={setEmail}
            autoFocus={Boolean(props.login)}
            placeholder="Adres email"
            disabled={op.state.pending || completePhase}
            onEnter={submit}
          />
        </FormItem>
      </Form>

      <Form disabled={op.state.pending}>
        {completePhase ? (
          <>
            {tokenSent && (
              <Text muted center block className="correct-token-sent-hint">
                Jeżeli podane dane są prawidłowe, kod autoryzacyjny został wysłany na podany adres email
              </Text>
            )}
            <FormItem label="Kod autoryzacyjny">
              <TextInput
                value={token}
                onChange={setToken}
                placeholder="Podaj otrzymany kod autoryzacyjny"
                disabled={op.state.pending}
                onEnter={submit}
                autoFocus
              />
            </FormItem>
            <FormItem label="Hasło">
              <TextInput
                type="password"
                value={newPassword}
                onChange={setNewPassword}
                placeholder="Podaj nowe hasło"
                disabled={op.state.pending}
                onEnter={submit}
              />
            </FormItem>

            <FormItem label="Hasło">
              <TextInput
                type="password"
                value={newPassword2}
                onChange={setNewPassword2}
                placeholder="Podaj nowe hasło ponownie"
                disabled={op.state.pending}
                onEnter={submit}
              />
            </FormItem>

            <ButtonGroup>
              <Button big ghost onClick={onCancel} disabled={op.state.pending}>
                Anuluj
              </Button>

              <Button big cta onClick={sendComplete} disabled={sendCompleteDisabled}>
                Zmień hasło
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <ButtonGroup>
            <Button big ghost onClick={onCancel} disabled={op.state.pending}>
              Anuluj
            </Button>
            <Button big cta onClick={sendToken} disabled={sendTokenDisabled}>
              Wyślij kod
            </Button>
          </ButtonGroup>
        )}

        {(op.state.pending || op.state.error) && (
          <div className="text-center">
            <PendingOperationStatus op={op.state} successTxt=" " pendingTxt="Proszę czekać..." />
          </div>
        )}
      </Form>
    </div>
  );
}
