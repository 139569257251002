import React from "react";

export const selectUtils = {
  formatItemDisplay,
  getItemKey,
};

export interface SelectEntryMetadata<T> {
  item: T;
  itemKey: any;
  index: number;
  focused: boolean;
  selected: boolean;
}

export interface ItemsRenderFnData<T> {
  entries: Array<SelectEntryMetadata<T>>;
  onSelect: (item: T | undefined) => void;
  entryDefaultRender: (item: SelectEntryMetadata<T>) => React.ReactNode;
}

export type ItemFilterPredicate<TItem> = (item: TItem, searchText: string, searchTextLower: string) => boolean;
export type ItemFiltering<TItem> =
  | "simpleIncludes"
  | "multitokenStartsWith"
  | "multitokenIncludes"
  | ItemFilterPredicate<TItem>;
export type ItemSearchValueFn<T> = (item: T) => string | undefined;
export type ItemKeyFn<T> = (item: T) => string | number | undefined;
export type ItemDisplayFn<T> = (item: T) => string | undefined;
export type ItemRenderFn<T> = (item: T) => React.ReactNode;
export type ItemsRenderFn<T> = (arg: ItemsRenderFnData<T>) => React.ReactNode;

function formatItemDisplay(item: any, display: any | undefined): string {
  if (typeof display !== "function") {
    if (typeof item === "string") return item;
    if (typeof item === "number") return item.toString();
    if (item === undefined || item === null) return "";
  }

  let displayValue: any;

  if (display) {
    if (typeof display === "string") {
      displayValue = item[display];
    } else if (typeof display === "function") {
      try {
        displayValue = display(item);
      } catch (e) {
        console.error(e);
        return "Display function error";
      }
    } else {
      return "Invalid display parameter";
    }
  } else {
    displayValue = item["label"];
  }

  if (typeof displayValue === "string") return displayValue;
  if (typeof displayValue === "number") return displayValue.toString();

  return (displayValue || "").toString();
}


function getItemKey(item: any, keySelector: any | undefined): string | number | undefined {
  if (typeof keySelector !== "function") {
    if (typeof item === "string") return item;
    if (typeof item === "number") return item;
    if (item === undefined || item === null) return undefined;
  }

  let keyValue: any;

  if (keySelector) {
    if (typeof keySelector === "string") {
      keyValue = item[keySelector];
    } else if (typeof keySelector === "function") {
      try {
        keyValue = keySelector(item);
      } catch (e) {
        console.error(e);
        return "Key function error";
      }
    } else {
      return undefined;
    }
  } else {
    keyValue = item["id"];
  }

  return keyValue;
}
