import { monthNames } from "core/utils";
import { useMemo } from "react";
import { SelectInput } from "../SelectInput";

interface SelectMonthProps {
  value: number | undefined;
  onChange: (value: number | undefined) => any;
  monthNumberBase?: number;
  disabled?: boolean;
}

export function SelectMonth(props: SelectMonthProps) {
  const { value, onChange, disabled } = props;
  const monthNumberBase = props.monthNumberBase || 0;
  const monthData = useMonthData(monthNumberBase);

  return (
    <SelectInput
      value={value}
      onChange={onChange}
      items={monthData.monthNumbers}
      display={x => monthData.monthNameByNumber[x]}
      disabled={disabled}
    />
  );
}

interface MonthData {
  monthNumbers: number[];
  monthNameByNumber: Record<number, string>;
}

function useMonthData(monthNumberBase: number): MonthData {
  return useMemo<MonthData>(() => {
    const result: MonthData = {
      monthNumbers: [],
      monthNameByNumber: {},
    };

    monthNames.forEach((value, key) => {
      const monthNumber = key + monthNumberBase;
      result.monthNumbers.push(monthNumber);
      result.monthNameByNumber[monthNumber] = value;
    });

    return result;
  }, [monthNumberBase]);
}
