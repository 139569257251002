import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import { Subject } from "rxjs";
import { Subtract } from "../utils/typescript";
import { WindowSerializedState } from "./desktopSave/DesktopSerializedState";
import { WindowStaticProps } from "./WindowTypes";
import { debounce } from "core/utils";

export interface IWindowViewContext {
  setTitle(title: string): void;
  setTitleExtension(titleExtension: string | undefined): void;
  setSize(widthHeight: [number, number]): void;
  setMinSize(widthHeight: [number | undefined, number | undefined]): void;
  setInitSize(widthHeight: [number, number]): void;
  setPosition(leftTop: [number, number]): void;
  setInitPosition(leftTop: [number, number]): void;
  maximize(): void;
  maximizeOnInit(): void;
  close(result?: any): void;
  activate(): void;
  setContentComponentSerializedState(state: any): void;
  dragging: boolean;
  onResize: Subject<{}>;
  onClick: Subject<{}>;
  onActivate: Subject<{}>;
  restoreState?: WindowSerializedState;
}

function noop() {}

const initialState: IWindowViewContext = {
  setTitle: noop,
  setTitleExtension: noop,
  setSize: noop,
  setMinSize: noop,
  setInitSize: noop,
  setPosition: noop,
  setInitPosition: noop,
  maximize: noop,
  maximizeOnInit: noop,
  close: noop,
  activate: noop,
  setContentComponentSerializedState: noop,
  dragging: false,
  onResize: new Subject<{}>(),
  onClick: new Subject<{}>(),
  onActivate: new Subject<{}>(),
};

export const WindowViewContext = React.createContext<IWindowViewContext>(initialState);

export interface InjectedWindowContext {
  window: IWindowViewContext;
}

export const withWindowContext = <P extends InjectedWindowContext>(
  Component: React.ComponentType<P> & WindowStaticProps
) =>
  class WithWindowContext extends React.Component<Subtract<P, InjectedWindowContext>> {
    static title = Component.title;
    static icon = Component.icon;
    static onlySu = Component.onlySu;
    static noPadding = Component.noPadding;
    static getNativeWindowUrl = Component.getNativeWindowUrl;
    static shouldOpenNativeWindow = Component.shouldOpenNativeWindow;
    static isAvailable = Component.isAvailable;
    static wrappedHocComponent = Component;
    static contextType = WindowViewContext;
    contextType!: React.ContextType<typeof WindowViewContext>;

    render() {
      return <Component {...(this.props as P)} window={this.context} />;
    }
  };

export function useInitWindowSize(width: number, height: number) {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.setInitSize([width, height]);
  }, [width, height]); // eslint-disable-line
}

export function useWindowSize(width: number, height: number) {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.setSize([width, height]);
  }, [width, height]); // eslint-disable-line
}

export function useMinWindowSize(width?: number, height?: number) {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.setMinSize([width, height]);
  }, [width, height]); // eslint-disable-line
}

export function useWindowTitle(windowTitle: string) {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.setTitle(windowTitle);
  }, [windowTitle]); // eslint-disable-line
}

export function useWindowTitleExtension(windowTitleExtension: string | undefined) {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.setTitleExtension(windowTitleExtension);
  }, [windowTitleExtension]); // eslint-disable-line
}

export function useInitiallyMaximizedWindow() {
  const wndCtx = useContext(WindowViewContext);

  useLayoutEffect(() => {
    wndCtx.maximizeOnInit();
  }, []); // eslint-disable-line
}

export function useWindowComponentSerializedState(state: any) {
  const wndCtx = useContext(WindowViewContext);
  wndCtx.setContentComponentSerializedState(state);
}

export function useWindowContext() {
  return useContext(WindowViewContext);
}

export function useWindowResize(callbackOnResize: () => any) {
  const wndCtx = useContext(WindowViewContext);
  const callbackOnResizeRef = useRef(callbackOnResize);
  callbackOnResizeRef.current = callbackOnResize;

  useEffect(() => {
    const debounced = debounce(() => callbackOnResizeRef.current(), 10);
    const sub = wndCtx.onResize.subscribe(debounced);

    return () => {
      sub.unsubscribe();
    };
  }, [wndCtx]);
}
