import React, { useEffect } from "react";
import "./LoginPage.scss";
import { LoginPageRightContent } from "./LoginPageContent";

export function LoginPage() {
  useEffect(() => {
    document.documentElement.style.fontSize = "1.04822vmin";

    return () => {
      document.documentElement.style.fontSize = "";
    };
  }, []);

  return (
    <div className="login-page">
      <div className="left-column"></div>
      <div className="right-column">
        <LoginPageRightContent />
      </div>
    </div>
  );
}
