import React from "react";
import { formatElapsedSeconds } from "./utils";

interface ElapsedTimeProps {
  elapsedSeconds?: number;
  elapsedMinutes?: number;
}

export class ElapsedTime extends React.PureComponent<ElapsedTimeProps> {
  render() {
    const elapsedSeconds = this.props.elapsedSeconds || (this.props.elapsedMinutes || 0) * 60;
    const inputAsSeconds = typeof this.props.elapsedSeconds === "number";
    const formatted = formatElapsedSeconds(elapsedSeconds, inputAsSeconds);

    return <span>{formatted}</span>;
  }
}
