// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { User, VerifyPasswordOfCurrentUserCommand, VerifyPasswordOfCurrentUserResult, RecoverPasswordStartCommand, RecoverPasswordCompleteCommand, AuthInfo, LoginRequest, LoginResponse, LoginRequestWithPassChange, ImpersonateUserRequest } from './models';

const staticRequestHeaders = {
  'x-api-v2': '1'
}

const paths = {
  getLoggedUser: 'api/Auth/GetLoggedUser',
  verifyCurrentUserPassword: 'api/Auth/VerifyCurrentUserPassword',
  startPasswordRecovery: 'api/Auth/StartPasswordRecovery',
  completePasswordRecovery: 'api/Auth/CompletePasswordRecovery',
  info: 'api/auth/info',
  login: 'api/auth/login',
  login_with_pass_change: 'api/auth/login_with_pass_change',
  logout: 'api/auth/logout',
  impersonate: 'api/Auth/Impersonate',
};

function getLoggedUser(): Promise<User> {
  return axios.request<User>({
    url: paths.getLoggedUser,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function verifyCurrentUserPassword(data: VerifyPasswordOfCurrentUserCommand): Promise<VerifyPasswordOfCurrentUserResult> {
  return axios.request<VerifyPasswordOfCurrentUserResult>({
    url: paths.verifyCurrentUserPassword,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function startPasswordRecovery(data: RecoverPasswordStartCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.startPasswordRecovery,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function completePasswordRecovery(data: RecoverPasswordCompleteCommand): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.completePasswordRecovery,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

function info(): Promise<AuthInfo> {
  return axios.request<AuthInfo>({
    url: paths.info,
    method: 'get',
    headers: staticRequestHeaders,
  }).then(res => res.data);
}

function login(data: LoginRequest): Promise<LoginResponse> {
  return axios.request<LoginResponse>({
    url: paths.login,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function login_with_pass_change(data: LoginRequestWithPassChange): Promise<LoginResponse> {
  return axios.request<LoginResponse>({
    url: paths.login_with_pass_change,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  }).then(res => res.data);
}

function logout(): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.logout,
    method: 'get',
    headers: staticRequestHeaders,
  });
}

function impersonate(data: ImpersonateUserRequest): Promise<AxiosResponse<any>> {
  return axios.request<any>({
    url: paths.impersonate,
    method: 'post',
    headers: staticRequestHeaders,
    data,
  });
}

export const authService = {
  paths,
  getLoggedUser,
  verifyCurrentUserPassword,
  startPasswordRecovery,
  completePasswordRecovery,
  info,
  login,
  login_with_pass_change,
  logout,
  impersonate,
};
