import { Chip } from "core/content";
import { useTr } from "core/intl";
import { combineClassNames, ItemDisplayFn, ItemKeyFn, selectUtils, useDelayedEffect } from "core/utils";
import React, { useState } from "react";
import { Button } from "./Button";
import "./MutliselectSelectedItems.scss";

interface MutliselectSelectedItemsProps<TItem> {
  items: TItem[];
  selected: TItem[];
  itemKey?: keyof TItem | ItemKeyFn<TItem>;
  itemKeyAsModel?: boolean;
  display?: keyof TItem | ItemDisplayFn<TItem>;
  onDeselect: (item: TItem) => any;
  chipsInline?: boolean;
  disabled?: boolean;
}

export function MutliselectSelectedItems<TItem>(props: MutliselectSelectedItemsProps<TItem>) {
  const [showAll, setShowAll] = useState(false);
  const [cursorOver, setCursorOver] = useState(false);
  const tr = useTr();

  useDelayedEffect(
    () => {
      if (!cursorOver) setShowAll(false);
    },
    500,
    [cursorOver]
  );

  if (!props.selected.length) return null;

  const showAllItems = showAll || props.selected.length <= 3;

  const alwaysVisibleItems = showAllItems ? props.selected : props.selected.slice(0, 2);
  const remainingItems = showAllItems ? [] : props.selected.slice(2);
  const cls = combineClassNames("multiselect-selected-list", props.chipsInline ? "chips-inline" : undefined);

  return (
    <div className={cls} onMouseLeave={() => setCursorOver(false)} onMouseOver={() => setCursorOver(true)}>
      {alwaysVisibleItems.map((x, i) => {
        const fullItem = props.itemKeyAsModel ? props.items.find(item => getItemKey(item, props.itemKey) === x) : x;
        const displayTxt = tr(selectUtils.formatItemDisplay(fullItem, props.display));
        return (
          <Chip
            key={i}
            closeAction={props.disabled ? undefined : () => fullItem !== undefined && props.onDeselect(fullItem)}
            textEllipsis
            title={displayTxt}
          >
            {displayTxt}
          </Chip>
        );
      })}
      {Boolean(remainingItems.length) && (
        <Button ghost onClick={() => setShowAll(true)}>
          {tr("Pokaż pozostałe ({{remainingItemsCount}})", { remainingItemsCount: remainingItems.length })}
        </Button>
      )}
    </div>
  );
}

function getItemKey(item: any, itemKey: any) {
  return selectUtils.getItemKey(item, itemKey);
}
