import { usePhoneViewEnabled } from "core/global/phoneViewState";
import { lazy, Suspense } from "react";
import { MainViewSuspenseFallback } from "./MainViewSuspenseFallback";

const PhoneView = lazy(() => import("core/phone").then(m => ({ default: m.PhoneView })));
const DesktopView = lazy(() => import("core/desktop/DesktopView").then(m => ({ default: m.DesktopView })));

export function MainView() {
  const phoneViewEnabled = usePhoneViewEnabled();

  return (
    <Suspense fallback={<MainViewSuspenseFallback />}>{phoneViewEnabled ? <PhoneView /> : <DesktopView />}</Suspense>
  );
}
