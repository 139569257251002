import React from "react";
import { combineClassNames, Icon, IconSymbol } from "../utils";

export type FeedbackKind = "info" | "warning" | "error" | "success";

interface FeedbackIconProps extends React.HTMLAttributes<HTMLElement> {
  icon: FeedbackKind;
}

const colorByIcon: Record<FeedbackKind, string> = {
  info: "#3787FF",
  warning: "#FFCE4F",
  error: "#FF7161",
  success: "#5FC375",
};

const symbolByIcon: Record<FeedbackKind, IconSymbol> = {
  info: "infoCricle",
  warning: "exclamationTriangle",
  error: "exclamationTriangle",
  success: "checkCircleSolid",
};

export function FeedbackIcon(props: FeedbackIconProps) {
  const { icon, className, ...restAttrs } = props;
  const color = colorByIcon[icon];
  const sym = symbolByIcon[icon];
  const cls = combineClassNames("feedback-icon", className);

  return <Icon color={color} symbol={sym} className={cls} {...restAttrs} />;
}
