import { useState, useEffect, useRef, useCallback, Dispatch, SetStateAction, useMemo } from "react";
import { usePendingOperation, PendingOperationHook } from "./PendingOperationHook";

export interface DataLoaderOptions<TDefault = undefined> {
  resetDataOnDepsChange?: boolean;
  defaultValue?: TDefault;
}

export type DataLoaderRefreshFn<T> = (resetData?: boolean) => Promise<T>;
export type DataLoaderResult<T> = [T, PendingOperationHook<T>, DataLoaderRefreshFn<T>];

const emptyArray: any[] = [];

export function useAsyncLoadedList<T>(
  fetchFn: () => Promise<T[]>,
  deps?: any[],
  opts?: DataLoaderOptions<T[]>
): DataLoaderResult<T[]> {
  return useDataLoader(fetchFn, deps, { ...opts, defaultValue: emptyArray as T[] });
}

export function useDataLoader<T, TDefault = undefined>(
  fetchFn: () => Promise<T>,
  deps?: any[],
  opts?: DataLoaderOptions<TDefault>
): DataLoaderResult<T | TDefault> {
  const currentPromiseRef = useRef<Promise<T>>();
  const fetchFnRef = useRef(fetchFn);
  fetchFnRef.current = fetchFn;
  const optsRef = useRef(opts);
  optsRef.current = opts;

  const [data, setData] = useState<T>();
  const pendingOperation = usePendingOperation();
  const pendingOperationRef = useRef(pendingOperation);
  pendingOperationRef.current = pendingOperation;

  const runFetchProcess = useCallback((resetData?: boolean) => {
    currentPromiseRef.current = undefined;
    if (resetData) {
      setData(undefined);
    }

    const promise = fetchFnRef.current();
    currentPromiseRef.current = promise;
    pendingOperationRef.current.handle(promise);

    promise
      .then(res => {
        if (currentPromiseRef.current !== promise) return;

        setData(res);
      })
      .catch(err => {
        console.error(err);
        setData(undefined);
      });

    return promise;
  }, []);

  const refreshCallback = useCallback<DataLoaderRefreshFn<T>>(
    (resetData?: boolean) => {
      return runFetchProcess(resetData);
    },
    [runFetchProcess]
  );

  useEffect(() => {
    runFetchProcess(optsRef.current?.resetDataOnDepsChange);
  }, deps || []); // eslint-disable-line

  return [data || (opts?.defaultValue as any), pendingOperation, refreshCallback];
}

export function useIntervalDataLoader<T, TDefault = undefined>(
  fetchFn: () => Promise<T>,
  intervalTime: number,
  deps?: any[],
  opts?: DataLoaderOptions<TDefault>
): DataLoaderResult<T | TDefault> {
  const dataLoaderResult = useDataLoader(fetchFn, deps, opts);
  const [, op, refresh] = dataLoaderResult;
  const { pending, operationCounter } = op.state;
  const refreshRef = useRef(refresh);
  refreshRef.current = refresh;

  useEffect(() => {
    if (!pending) {
      const timer = window.setTimeout(() => refreshRef.current(), intervalTime);
      return () => window.clearTimeout(timer);
    }
  }, [pending, operationCounter, intervalTime]);

  return dataLoaderResult;
}

export function useInterval(callback: () => any, delay: number, immediate?: boolean) {
  const callbackRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => callbackRef.current && callbackRef.current();

    if (delay) {
      if (immediate) tick();

      const handle = window.setInterval(tick, delay);
      return () => window.clearInterval(handle);
    }
  }, [delay, immediate]);
}

export function useIntervalEffect(effect: React.EffectCallback, delay: number, deps?: any[]) {
  const [trigger, setTrigger] = useState(0);
  const timeoutDeps = [...(deps || []), delay, trigger];
  const effectDeps = [...(deps || []), trigger];

  useEffect(() => {
    const handle = window.setTimeout(() => setTrigger(trigger + 1), delay);
    return () => window.clearTimeout(handle);
  }, timeoutDeps); // eslint-disable-line

  useEffect(effect, effectDeps); // eslint-disable-line
}

interface AddEditModelStateResult<T> {
  model: T;
  setModel: Dispatch<SetStateAction<T>>;
  loadingModel: Promise<T> | undefined;
  hasModelChanged: boolean;
  originalModel: T;
  markModelAsOriginal: () => any;
  reloadModel: () => any;
}

export function useAddEditModelState<T>(
  addInitState: T | (() => T),
  fetchStateToEdit: (() => Promise<T> | undefined) | undefined,
  deps?: any[]
): AddEditModelStateResult<T> {
  const fetchStateToEditRef = useRef(fetchStateToEdit);
  fetchStateToEditRef.current = fetchStateToEdit;
  const lastPromiseRef = useRef<Promise<T>>();

  const [originalStateToCompare, setOriginalStateToCompare] = useState<T>(addInitState);
  const [state, setState] = useState<T>(addInitState);
  const stateRef = useRef(state);
  stateRef.current = state;
  const [promise, setPromise] = useState<Promise<T>>();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const hasModelChanged = useChangedJsonDiff(state, originalStateToCompare);
  const loadDeps = [...(deps || []), refreshTrigger];

  const reloadModel = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  useEffect(() => {
    setState(addInitState);
    setOriginalStateToCompare(addInitState);
    if (!fetchStateToEditRef.current) {
      setPromise(undefined);
      return;
    }

    const promiseOrUndefined = fetchStateToEditRef.current();
    lastPromiseRef.current = promiseOrUndefined;
    setPromise(promiseOrUndefined);

    if (promiseOrUndefined) {
      promiseOrUndefined.then(res => {
        if (promiseOrUndefined !== lastPromiseRef.current) return;

        setState(res);
        setOriginalStateToCompare(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, loadDeps);

  const markModelAsOriginal = useCallback(() => {
    setOriginalStateToCompare(stateRef.current);
  }, []);

  return {
    model: state,
    setModel: setState,
    loadingModel: promise,
    hasModelChanged,
    originalModel: originalStateToCompare,
    markModelAsOriginal,
    reloadModel,
  };
}

export function useChangedJsonDiff(obj1: any, obj2: any) {
  return useMemo(() => {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }, [obj1, obj2]);
}
